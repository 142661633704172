<template>
  <div class="country-select-outer">
    <div class="input-label" v-if="label">{{ label }}</div>

        <div 
            class="form-select custom-select"
            :class="[
                disabled && 'hidearrow',
                errorMessage ? 'red-bordered' : ''
            ]"  
            ref="select"
        >
            <div 
                class="selected-option" 
                :class="isCapitalize ? 'text-capitalize' : ''"
                @click="toggleOptions" 
                v-if="!disabled"
            >
                <span :class="{'gray90-text': selectedOption.label === defaultOption.label}">
                    {{ selectedOption.label }}
                </span>
            </div>

      <div
        class="disabled-selected-option"
        :class="isCapitalize ? 'text-capitalize' : ''"
        v-else
      >
        {{ selectedOption.label }}
      </div>

      <div class="options" v-if="isOpen">
        <div class="select-search-outer">
          <img
            src="@/assets/images/wallet/search-icon.svg"
            class="search-icon"
            width="22"
            height="20"
          />
          <input
            type="text"
            v-model="search"
            :placeholder="$t('Kyc.searchIndustry')"
            @blur="closeOptions"
            class="select-search-input"
            ref="search"
          />
        </div>

        <div
          class="option"
          v-for="option in filteredData"
          :key="option.id"
          @click="changeOption(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>

    <div class="remark-text">{{ remarkText }}</div>
    <div class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    isShowIcon: { type: Boolean, default: true },
    label: { type: String, default: "" },
    defaultOptionText: { type: String, default: "" },
    selectedIndustry: { type: String, default: "" },
    assignedData: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    remarkText: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
    isCapitalize: { type: Boolean, default: true },
  },

  data() {
    return {
      isOpen: false,
      selectedOption: "",
      search: "",
      filteredData: this.assignedData,
    };
  },

  computed: {
    defaultOption() {
      let defaultText =
        this.defaultOptionText === ""
          ? this.$t("Kyc.selectIndustry")
          : this.defaultOptionText;
      return { id: "", label: defaultText };
    },

    options() {
      return this.assignedData;
    },
  },

  mounted() {
    this.filteredData = this.assignedData;

    if (this.selectedIndustry != "0" && this.selectedIndustry !== "") {
      this.assignValue(this.selectedIndustry);
    } else {
      this.changeOption(this.defaultOption);
    }

    this.$refs.select.focus();
  },

  watch: {
    assignedData: {
      handler() {
        this.options = this.assignedData;
        this.filteredData = this.assignedData;
      },
    },

    selectedIndustry: {
      handler() {
        if (this.selectedIndustry != "0" && this.selectedIndustry != "") {
          this.assignValue(this.selectedIndustry);
        } else {
          this.changeOption(this.defaultOption);
        }
      },
    },

    search: {
      handler() {
        this.filteredOptions(this.search);
      },
    },

    isOpen: {
      handler() {
        if (this.isOpen) {
          this.$nextTick(() => {
            this.$refs.search.focus();
          });
        }
      },
    },
  },

  methods: {
    assignValue(industry) {
      let data = this.options;

      let selected = data.filter((option) => {
        return option.label.toLowerCase() == industry.toLowerCase();
      });

      if (selected.length <= 0) {
        this.changeOption(this.defaultOption);
      } else {
        this.changeOption(selected[0]);
      }
    },

    filteredOptions(text) {
      let filtered = this.options;

      this.filteredData = filtered.filter((option) =>
        option.label.toLowerCase().includes(text.toLowerCase())
      );
    },

    toggleOptions() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.addEventListener("click", this.closeOptions);
      } else {
        document.removeEventListener("click", this.closeOptions);
      }
    },

    closeOptions(event) {
      if (this.$refs.select) {
        if (!this.$refs.select.contains(event.target)) {
          this.search = "";
          this.isOpen = false;
          document.removeEventListener("click", this.closeOptions);
        }
      }
    },

    changeOption(option) {
      this.$emit("clearError");
      this.selectedOption = option;
      this.search = "";
      this.isOpen = false;
      this.$emit("select", option.label);
      document.removeEventListener("click", this.closeOptions);
    },
  },
};
</script>

<style></style>
