<template>
   <div class="input-outer-container" id="birthdate-input">
        <div class="input-label" v-if="label != ''">
            {{ label }}
        </div>

        <div class="input-container">
            <DatePicker 
                :name="name"
                :value="value"
                type="date"
                :disabled="disabled"
                :disabled-date="disabledDates"
                :placeholder="placeholder"
                :default-value="minDate"
                @input="$emit('update', $event)"
                :clearable="false"
                :format="format"
                :editable="isEditable"
                @focus="showCross = true"
                @blur="hideCross()"
            >
                <img slot="icon-calendar" src="@/assets/images/kyc/calendar_primary.svg"/>
            </DatePicker>

            <button @click="$emit('reset')" class="birthdate-input-close" v-show="showCross">
                <v-icon>mdi-close</v-icon>
            </button>
        </div>

        <div class="remark-text">{{ remarkText }}</div>
        <div class="error-message">{{ errorMessage }}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: { type: String, default: '' },
        name: { type: String },
        placeholder: { type: String },
        value: { type: Date },
        errorMessage: { type: String, default: '' },
        remarkText: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        format: { type: String, default: "DD-MM-YYYY" },
        isEditable: { type: Boolean, default: false }
    },

    model: {
        prop: "value",
        event: "update"
    },

    mounted() {
        let today = new Date();
        let minDate = new Date();
        minDate.setFullYear(today.getFullYear() - 18);
        this.minDate = minDate;
    },

    data() {
        return {
            minDate: new Date(),
            showCross: false,
            hideCrossTimeout: ""
        }
    },

    methods: {
        disabledDates(date) {
            return date > this.minDate;
        },

        hideCross() {
            clearTimeout(this.hideCrossTimeout);
            this.hideCrossTimeout = setTimeout(() => {
                this.showCross = false;
            }, 250); 
        }
    }
}
</script>

<style>

</style>