<template>
    <router-link :to="generateRouteLink(routeName)">
    <div
        :class="
            $route.name && $route.name.includes(routeName) ? 
            'active-header-title' : 
            'header-title'
        "
    >
        {{ title }}
    </div>
</router-link>
</template>

<script>
import { generateCodeFrame } from '@vue/compiler-sfc';

export default {
    props: {
        title: { type: String, default: "" },
        routeName: { type: String, default: "" },
    }
}
</script>

<style>
.active-header-title,
.header-title {
    display: flex;
    flex-direction: row;
}
</style>