<template>
  <div
    class="main-outer"
    id="kyc-status-topbar"
    :class="kyc_status === 'pending' ? 'primary-color-text' : 'fail-text'"
    v-if="
      isLogin &&
      kyc_status !== 'approved' &&
      (
        $route.name &&
        !$route.name.includes('ExchangeView') &&
        !$route.name.includes('KycView') &&
        !$route.name.includes('BusinessKyc')
      )
    "
    @click="routerKyc"
  >
    <div class="main-wrapper">
      <img
        :src="
          require(`@/assets/images/profile/${
            kyc_status === 'pending' ? 'approved.png' : 'rejected.png'
          }`)
        "
      />

      <div>
        {{
          kyc_status === "incomplete"
            ? $t("Profile.kycIncompleteRibbon")
            : kyc_status === "pending"
            ? $t("Profile.kycPendingRibbon")
            : $t("Profile.kycRejectRibbon")
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    isLogin() {
      return this.$store.state.auth.isLoggedIn;
    },

    kyc_status() {
      return this.$store.state.user.kyc_status;
    },
  },

  methods: {
    routerKyc() {
      if (this.kyc_status !== "approved") {
        if (!this.$store.state.user.business) {
          this.navigateToRoute("KycView");
        } else {
          this.navigateToRoute("BusinessKyc");
        }
      }
    },
  },
};
</script>

<style>
#kyc-status-topbar.main-outer {
  background-color: var(--white);
  padding: 10px;
  cursor: pointer;
}

#kyc-status-topbar .main-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#kyc-status-topbar img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  #kyc-status-topbar .main-wrapper {
    align-items: start;
    margin: 0;
    font-size: 14px;
  }
}
</style>
