<template>
    <div>
      <button v-if="showButton" @click="scrollToTop" class="scroll-to-top-btn">
    <v-icon>
        mdi-arrow-up
    </v-icon></button>
    </div>
  </template>

  <script>
  export default {
    data() {
      return {
        showButton: false,
      }
    },
    methods: {
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      },
      handleScroll() {
        if (window.scrollY > 300) {
          this.showButton = true;
        } else {
          this.showButton = false;
        }
      },
    },
    beforeMount() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  }
  </script>

  <style>
  .scroll-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    border-radius: 50%;
    background-color: var(--primary60);
    color: white;
    font-size: 16px;
    cursor: pointer;
    z-index: 1000;
  }
  </style>