//PAYMENT GATEWAY
import api from "@/assets/api/api.js";

const state = {
  request_id: "",
  callback_url: "",
  status: "",
  reference_id: "",
};

const mutations = {
  SET_PAYMENT(state, data) {
    state.request_id = data.request_id;
    state.callback_url = data.callback_url;
    state.reference_id = data.reference_id;
    state.status = data.status;
  },
};

const actions = {
  async paymentGatewayRequest(context, {params, secretKey, publicKey}) {
    const crypto = require("crypto-js");
    const sortedData = Object.keys(params)
      .sort()
      .reduce((result, key) => {
        result[key] = params[key];
        return result;
      }, {});

    const jsonString = JSON.stringify(sortedData);
    const signature = crypto
      .HmacSHA256(jsonString, secretKey)
      .toString(crypto.enc.Hex);
    let response = await api.apiCall(
      "POST",
      "/payment/sapi/request",
      params,
      "",
      "",
      publicKey,
      signature
    );

    if (response.status !== "success") {
      throw response;
    } else {
      return response;
    }
  },

  async paymentGetRequest({ commit }, params) {
    let response = await api.apiCall("GET", "/payment/api/request", params);
    if (response.status !== "success") {
      throw response;
    } else {
      commit("SET_PAYMENT", response.data);
      return response;
    }
  },

  async paymentVerification({ commit }, params) {
    let response = await api.apiCall(
      "POST",
      "/payment/jwt/send/save-data",
      params
    );

    if (response.status !== "success") {
      throw response;
    } else {
      return response.data;
    }
  },

  async paymentSubmit({ commit }, params) {
    let response = await api.apiCall("POST", "/payment/jwt/send", params);
    if (response.status !== "success") {
      throw response;
    } else {
      commit("SET_PAYMENT", response.data);
      return response;
    }
  },

  setPaymentRequest({ commit }, data) {
    commit("SET_PAYMENT", data);
  },
};

export const payment = {
  namespaced: true,
  actions,
  state,
  mutations,
};
