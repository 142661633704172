<template>
  <div class="country-select-outer">
    <div class="input-label" v-if="label">{{ label }}</div>

    <div 
        class="form-select custom-select" 
        :class="[
            disabled && 'hidearrow',
            errorMessage ? 'red-bordered' : ''
        ]" 
        ref="select"
    >
        <div 
            class="selected-option" 
            :class="{'gray90-text': selectedOption === defaultOption}" 
            @click="toggleOptions" 
            v-if="!disabled"
        >
            {{ selectedOption }}
        </div>

        <div class="disabled-selected-option" v-else>
            {{ selectedOption }}
        </div>

            <div class="options" v-if="isOpen">
                <div class="select-search-outer">
                    <img
                        src="@/assets/images/wallet/search-icon.svg"
                        class="search-icon"
                        width="22"
                        height="20"
                    />
                    <input
                        type="text"
                        v-model="search"
                        :placeholder="$t('Profile.searchState')"
                        @blur="closeOptions"
                        class="select-search-input"
                        ref="search"
                    />
                </div>
                
                <div 
                    class="option gray90-text" 
                    v-if="selectedOption == ''"
                    @click="changeOption(defaultOption)"
                >
                    {{ $t("Kyc.selectState") }}
                </div>
                <div
                    class="option"
                    v-for="option in filteredData"
                    :key="option.shortCode"
                    @click="changeOption(option.name)"
                >
                    {{ option.name }}
                </div>
            </div>
        </div>

        <div class="remark-text" v-if="remarkText">{{ remarkText }}</div>
        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
import countryList from "../../../public/data.json";

export default {
  props: {
    label: { type: String, default: "" },
    defaultOptionText: { type: String, default: "" },
    selectedCountry: { type: String, default: "MY" },
    selectedState: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    remarkText: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
  },

  data() {
    return {
      isOpen: false,
      selectedOption: "",
      search: "",
      options: countryList,
      filteredData: [],
    };
  },

  computed: {
    defaultOption() {
      let defaultText =
        this.defaultOptionText === ""
          ? this.$t("Kyc.selectState")
          : this.defaultOptionText;
      return defaultText;
    },
  },

  mounted() {
    this.manageOption();

    if (this.selectedState == "") {
      this.changeOption(this.defaultOption);
    } else {
      this.assignValue(this.selectedState);
    }

    this.$refs.select.focus();
  },

  watch: {
    selectedCountry: {
      handler() {
        this.manageOption();
      },
    },

    selectedState: {
      handler() {
        if (this.selectedState === "") {
          this.changeOption(this.defaultOption);
        } else if (this.selectedState != this.defaultOption) {
          this.assignValue(this.selectedState);
        }
      },
    },

    search: {
      handler() {
        this.filteredOptions(this.search);
      },
    },

    isOpen: {
      handler() {
        if (this.isOpen) {
          this.$nextTick(() => {
            this.$refs.search.focus();
          });
        }
      },
    },
  },

  methods: {
    manageOption() {
      this.options = [];
      let country =
        this.selectedCountry !== "" && this.selectedCountry != "0"
          ? this.selectedCountry
          : "MY";
      let filtered = countryList.filter((i) => i.countryShortCode == country);
      this.options = filtered[0].regions;

      if (this.selectedState !== "") {
        let selected = this.options.filter((option) => {
          return option.name.toLowerCase() == this.selectedState.toLowerCase();
        });

        if (selected.length == 0) {
          this.changeOption(this.defaultOption);
        }
      }

      this.filteredData = this.options;
    },

    assignValue(state) {
      if (this.selectedState !== "") {
        let data = this.options;

        let selected = data.filter((option) => {
          return option.name.toLowerCase() == state.toLowerCase();
        });

        if (selected.length == 0) {
          this.changeOption(this.defaultOption);
        } else {
          this.changeOption(selected[0].name);
        }
      }
    },

    filteredOptions(text) {
      let filtered = this.options;

      this.filteredData = filtered.filter((option) =>
        option.name.toLowerCase().includes(text.toLowerCase())
      );
    },

    toggleOptions() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.addEventListener("click", this.closeOptions);
      } else {
        document.removeEventListener("click", this.closeOptions);
      }
    },

    closeOptions(event) {
      if (this.$refs.select) {
        if (!this.$refs.select.contains(event.target)) {
          this.search = "";
          this.isOpen = false;
          document.removeEventListener("click", this.closeOptions);
        }
      }
    },

    changeOption(option) {
      this.$emit("clearError");
      this.selectedOption = option;
      this.search = "";
      this.isOpen = false;
      this.$emit("select", option);
      document.removeEventListener("click", this.closeOptions);
    },
  },
};
</script>

<style scoped>
.country-select-outer {
  margin-bottom: 10px;
}
</style>
