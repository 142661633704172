<template>
    <div class="txn-history-row" @click="$emit('getDetails', details)">
        <div class="txn-history-row-left">
            <div class="txn-history-icon">
                <img :src="require(`@/assets/images/${item.image}`)" width="25px" />
            </div>

            <div>
                <div class="txn-history-title">{{ item.title }}</div>
                <div class="txn-history-date">
                    {{ dateFormatter(item.date * 1000, "YYYY-MM-DD HH:mm:ss") }}
                </div>
            </div>  
        </div>

        <div class="txn-history-amount">
            <div :class="item.isSend || item.isBankCharges ? 'fail-text' : ''">
                {{ item.amount }}
            </div>

            <div class="txn-history-status">
                <div 
                    class="txn-history-dot"
                    :class="returnStatusColor(item.status)"
                >
                </div>
                <div class="txn-history-status-text">
                    {{ item.status }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: { type: Object },
        details: { type: Object }
    }
}
</script>

<style>

</style>