<template>
    <v-dialog v-model="isShow" class="dialog-modal" max-width="500px" :persistent="persistent"
        @click:outside="hideModal">
        <div class="modal-body-custom">
            <button @click="hideModal" class="modal-close-btn">
                <img src="@/assets/images/modal/close-circle.png" width="22px" />
            </button>

            <div class="modal-title">{{ title }}</div>
            <div class="modal-description" v-if="description">{{ description }}</div>

            <div class="modal-search-bar">
                <InlineSvg :src="require('@/assets/images/wallet/search-icon.svg')" width="25px" @click="focusInput"
                    v-if="!isSearchExpanded" />

                <input name="search" ref="searchInput" v-model="searchQuery" :placeholder="searchPlaceholder"
                    class="modal-search-input" :class="isSearchExpanded ? 'searchShow bg-gray20' : ''"
                    @blur=" !searchQuery ? isSearchExpanded = modelValue ? true : false : '' "/>

                <button @click="clearText" class="input-close bg-gray20" v-if="searchQuery && isSearchExpanded">
                    <v-icon>mdi-close</v-icon>
                </button>
            </div>

            <div class="modal-crypto-content" :class="heightAuto ? 'h-auto' : ''">
                <div v-if="filteredData.length === 0">
                    <span v-if="searchQuery">{{ $t("wallet.noDataFor") }} "{{ searchQuery }}"</span>
                    <span v-else>{{ $t("Trading.noData") }}</span>
                </div>


                <div v-else>
                    <div :class="isBank ? 'modal-bank-row' : 'modal-crypto-row'" v-for="item, index in filteredData"
                        :key="index" @click="$emit('action', item)">
                        {{ item[displayAttribute] }}
                        <v-icon v-if="isRightArrow">mdi-chevron-right</v-icon>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isBank: { type: Boolean, default: false },
        dialog: { type: Boolean, default: false },
        title: { type: String, default: "" },
        description: { type: String, default: "" },
        isSearch: { type: Boolean, default: false },
        searchPlaceholder: { type: String, default: "" },
        displayAttribute: { type: String, default: "" },
        data: { type: Array },
        isRightArrow: { type: Boolean, default: false },
        heightAuto: { type: Boolean, default: false },
        filterFunction: { type: Function },
        persistent: { type: Boolean, default: false }
    },

    data() {
        return {
            filteredData: [],
            searchQuery: "",
            isSearchExpanded: false,
        }
    },

    computed: {
        isShow() {
            return this.dialog;
        }
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },

    watch: {
        data: {
            handler() {
                this.filteredData = this.data;
            }
        },

        searchQuery: {
            handler() {
                this.filter(this.searchQuery);
            }
        }
    },

    methods: {
        hideModal() {
            this.$emit("close");
            this.clearText();
        },

        filter(text) {
            let filtered = this.data;
            let result = this.filterFunction(filtered, text);
            this.filteredData = result;
        },

        clearText() {
            this.searchQuery = "";
            this.focusInput();
        },


        focusInput() {
            this.isSearchExpanded = true;
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus();
                }
            });
        },

        toggleSearch() {
            this.isSearchExpanded = !this.isSearchExpanded;
            if (this.isSearchExpanded) {
                this.focusInput();
            }
        },
        handleClickOutside(event) {
            const searchBar = this.$refs.searchBar;
            if (searchBar && !searchBar.contains(event.target)) {
                this.isSearchExpanded = false;

            }
        }
    }
}
</script>

<style></style>