<template>
  <v-dialog
    v-model="isShow"
    class="dialog-modal"
    max-width="500px"
    :persistent="persistent"
    @click:outside="$emit('close')"
  >
    <div class="modal-body-custom" :id="modalId">
      <button @click="$emit('close')" class="modal-close-btn" v-if="isCloseBtn">
        <img src="@/assets/images/modal/close-circle.png" width="22px"/>
      </button>
      <img
        :src="require(`@/assets/images/modal/info-circle.png`)"
        class="modal-image"
        width="100px"
      />

      <div class="modal-title" :class="titleClass" v-if="title">
        {{ title }}
      </div>

      <div class="modal-description" v-if="heading">
        {{ heading }}
      </div>
      <div
        class="modal-description-item"
        v-for="(item, index) in descriptions"
        :key="index"
      >
        <div class="description-container">
          <div class="title-container">
            <span class="modal-description-title">{{ item.title }}</span>
          </div>
          <div class="colon-container">
            <span class="modal-description-colon">:</span>
          </div>
          <div class="value-container">
            <span class="modal-description-value">{{ item.value }}</span>
          </div>
        </div>
      </div>

      <div class="modal-description" v-if="heading2">
        {{ heading2 }}
      </div>
      <div
        class="modal-description-item"
        v-for="(item, index) in descriptions2"
        :key="index"
      >
        <div class="description-container">
          <div class="title-container">
            <span class="modal-description-title">{{ item.title }}</span>
          </div>
          <div class="colon-container">
            <span class="modal-description-colon">:</span>
          </div>
          <div class="value-container">
            <span class="modal-description-value">{{ item.value }}</span>
          </div>
        </div>
      </div>

      <!-- <slot name="description" v-if="!description"></slot> -->

      <RemarkContainer
        :icon="remark.icon"
        :isWarning="remark.isWarning"
        :text="remark.text"
        v-if="remark"
      />
      <div class="txn-details-body-content" v-if="details">
        <!-- EXCHANGE PAIR -->
        <div class="txn-details-row" v-if="details.exchangePair">
          <div class="txn-details-row-title">
            {{ $t("Trading.exchangePair") }}
          </div>
          <div class="txn-details-row-content">
            {{ details.exchangePair }}
          </div>
        </div>

        <!-- EXCHANGE AMOUNT -->
        <div class="txn-details-row" v-if="details.exchangeAmount">
          <div class="txn-details-row-title">{{ $t("Trading.amount") }}</div>
          <div class="txn-details-row-content">
            {{ details.exchangeAmount }}
          </div>
        </div>

        <!-- CURRENT MARKET PRICE -->
        <div class="txn-details-row" v-if="details.marketPrice">
          <div class="txn-details-row-title">
            {{ $t("Trading.currentMarket") }} ({{ details.quoteSymbol }})
          </div>
          <div class="txn-details-row-content">
            {{ details.marketPrice }}
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalId: { type: String },
    dialog: { type: Boolean },
    image: { type: String },
    title: { type: String },
    titleClass: { type: String, default: "" },
    heading: { type: String, default: "" },
    descriptions: { type: Array, default: () => [] },
    heading2: { type: String, default: "" },
    descriptions2: { type: Array, default: () => [] },
    remark: { type: Object, default: () => {} },
    buttonText: { type: String },
    buttonText2: { type: String, default: "" },
    isCloseBtn: { type: Boolean, default: true },
    persistent: { type: Boolean, default: false },
    details: { type: Object },
  },

  computed: {
    isShow() {
      return this.dialog;
    },
  },
};
</script>

<style>

#modal-status-requestkyc .modal-description {
  white-space: break-spaces;
}

#min-deposit-modal .modal-description {
  align-self: start;
  padding: 0 30px;
  font-weight: 600;
}

#min-deposit-modal, #min-withdrawal-modal{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  padding: 10px;
}
.modal-image {
  place-self: center;
}

.description-container {
 display: flex;
 align-items: center;
 padding: 0 30px;
 justify-content: space-between;
}

.title-container, .value-container {
 flex: 1; 
 color: var(--gray);
}

.title-container {
  text-align: left;
}

.value-container {
  text-align: right;
}

.colon-container {
 display: flex;
 justify-content: center; 
 flex: 0; 
 margin-right: 4.5rem
}

.modal-image + .modal-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-description-item{
  margin-bottom: 2px;
}

@media only screen and (max-width: 468px) {
  .description-container, #min-withdrawal-modal, #min-deposit-modal {
    padding: 0 !important;
    font-size: 0.9rem;
  }

  #min-withdrawal-modal .modal-title, #min-deposit-modal .modal-title{
    width: 100%;
  }

}
</style>
