import api from "@/assets/api/api.js";

const state = {
  showProhibitedModal: false,
  prohibited_countries: "",
  highRiskCountries: "",
  reasonList: "",
  employmentList: "",
  industryList: "",
  originFunds: "",
};

const mutations = {
  SET_PROHIBITED_MODAL(state, modal) {
    state.showProhibitedModal = modal;
  },
  SET_PROHIBITED_COUNTRIES(state, prohibited_countries) {
    state.prohibitedCountries = prohibited_countries;
  },
  SET_HIGH_RISK_COUNTRIES(state, high_Risk_Countries) {
    state.highRiskCountries = high_Risk_Countries;
  },
  SET_REASON_LIST(state, use_reason) {
    state.reasonList = use_reason;
  },
  SET_EMPLOYMENT_LIST(state, employment_list) {
    state.employmentList = employment_list;
  },
  SET_INDUSTRY_LIST(state, industry_list) {
    state.industryList = industry_list;
  },
  SET_ORIGIN_FUNDS_LIST(state, origin_funds_list) {
    state.originFunds = origin_funds_list;
  },
};

const actions = {
  showProhibitedModal({ commit }, modal) {
    commit("SET_PROHIBITED_MODAL", modal);
  },

  // GET KYC ITEMS
  async getDictList(context, params) {
    let response = await api.apiCall("GET", "/identity/api/dict", params);

    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response.message);
    }
  },

  async getKycItems({ commit }) {
    let response = await api.apiCall("GET", "/identity/api/kycItem");

    if (response.status === "success") {
      commit("SET_PROHIBITED_COUNTRIES", response.data.prohibitedCountries);
      commit("SET_HIGH_RISK_COUNTRIES", response.data.highRiskCountries);
      commit("SET_REASON_LIST", response.data.useReason);
      commit("SET_EMPLOYMENT_LIST", response.data.employmentStatus);
      commit("SET_INDUSTRY_LIST", response.data.industry);
      commit("SET_ORIGIN_FUNDS_LIST", response.data.originFunds);
    } else {
      console.error(response.message);
    }
  },

  async getAuthenticationInfo() {
    let response = await api.apiCall("GET", "/identity/jwt/users/kyc");

    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response.message);
    }
  },

  // PERSONAL DATA
  async submitPersonalData(context, params) {
    let response = await api.apiCall(
      "POST",
      "/identity/jwt/users/kyc/personalData",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  // ADDRESS
  async submitAddress(context, params) {
    let response = await api.apiCall(
      "POST",
      "/identity/jwt/users/kyc/userAddress",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  // ATTACHMENT
  async okayId(context, params) {
    let response = await api.apiCall(
      "POST",
      "/identity/jwt/users/kyc/okayId",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async okayFace(context, params) {
    let response = await api.apiCall(
      "POST",
      "/identity/jwt/users/kyc/okayFace",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async okayDoc(context, params) {
    let response = await api.apiCall(
      "POST",
      "/identity/jwt/users/kyc/okayDoc",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async uploadAttachment(context, params) {
    let response = await api.apiCall(
      "POST",
      "/identity/jwt/users/kyc/attachment",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },
};

export const kyc = {
  namespaced: true,
  state,
  mutations,
  actions,
};
