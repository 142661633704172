import api from "@/assets/api/api.js";

const actions = {
    async getFiatHistory(context, params) {
        let response = await api.apiCall("GET", "/fiat/jwt/get-transaction", params);

        if(response.status === "success") {
            let data = response.data.transactionsFiatRes != null ? response.data.transactionsFiatRes : [];
            return {
                history: data,
                pages: response.data.pages
            };
        } else {
            console.error(response.message);
        }
    },

    // BANK
    async getBank() {
        let response = await api.apiCall("GET", "/fiat/jwt/users/banks");

        if(response.status === "success") {
            return {
                bank: response.data != null ? response.data : []
            }
        } else {
            console.error(response.message);
        }
    },

    async requestAddNewBank(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/users/bank/save-data", params);

        if(response.status == "success") {
           return response.data;
        } else {
            throw response
        }
    },

    async addNewBank(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/users/bank", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    async deleteUserBank(context, params) {
        let response = await api.apiCall("DELETE", "/fiat/jwt/users/bank", params);

        if(response.status !== "success") {
            throw response;
        }

    },

    async requestWithdraw(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/request-withdraw/save-data", params);

        if(response.status === "success") {
            return response.data
        } else {
            throw response;
        }    
    },

    // WITHDRAWAL
    async withdraw(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/request-withdraw", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    // DEPOSIT
    async getDepBankInfo(context, params) {
        let response = await api.apiCall("GET", "/fiat/jwt/internal-fiat", params);

        if(response.status === "success") {
            let data = response.data != null ? response.data : [];

            return {
                bank: data[0]
            }
        } else {
            console.error(response.message);
        }
    },

    async requestDeposit(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/request-deposit", params);

        if(response.status !== "success") {
            throw response;
        }
    },
    
    async getTransactionDetails(context, params) {
        let response = await api.apiCall("GET", "/fiat/jwt/get-transaction-details", params);

        if(response.status === "success"){
            return response.data
        } else{
            throw response
        }
    },

    async getRemainingLimit(context) {
        let response = await api.apiCall(
          "GET",
          "/fiat/jwt/withdrawal/remaining-limit"
        );
    
        if (response.status === "success") {
          return response.data;
        } else {
          console.error(response.message);
        }
      },
}

export const fiat = {
    namespaced: true,
    // state,
    // mutations, 
    actions
}