<template>
    <v-dialog v-model="isShow" class="dialog-modal" max-width="500px" :persistent="persistent"
        @click:outside="hideModal" :id="modalId">
        <div class="modal-body-custom">
            <button @click="hideModal" class="modal-close-btn">
                <img src="@/assets/images/modal/close-circle.png" width="22px" />
            </button>

            <div class="modal-title">{{ title }}</div>
            <div class="modal-description" v-if="description">{{ description }}</div>

            <div class="modal-search-bar" v-if="isSearch">
                <InlineSvg :src="require('@/assets/images/wallet/search-icon.svg')" @click="focusInput"
                    v-if="!expandSearch" />

                <input name="search" ref="searchInput" v-model="searchQuery" :placeholder="searchPlaceholder"
                    class="modal-search-input" :class="expandSearch ? 'searchShow bg-gray20' : ''"
                    @blur="!searchQuery ? expandSearch = modelValue ? true : false : ''" autocomplete="off" />

                <button @click="clearText" class="input-close bg-gray20" v-if="searchQuery">
                    <v-icon>mdi-close</v-icon>
                </button>
            </div>

            <div class="modal-crypto-content" :class="heightAuto ? 'h-auto' : ''">
                <div v-if="filteredData.length === 0">
                    <span v-if="searchQuery">{{ $t("wallet.noDataFor") }} "{{ searchQuery }}"</span>
                    <span v-else>{{ $t("Trading.noData") }}</span>
                </div>

                <div v-else>
                    <div :class="isBank ? 'modal-bank-row' : 'modal-crypto-row'" v-for="item, index in filteredData"
                        :key="index" @click="selectItem(item, index)">
                        <div class="checkbox-group">
                            <input type="checkbox" :checked="activeIndex === index" />
                            <label :for="index">{{ item[displayAttribute] }}</label>
                        </div>
                        <v-icon v-if="isRightArrow">mdi-chevron-right</v-icon>
                    </div>
                </div>
            </div>
            <button class="main-btn w-100" @click="confirmSelection">{{ $t("Button.confirm") }}</button>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isBank: { type: Boolean, default: false },
        dialog: { type: Boolean, default: false },
        title: { type: String, default: "" },
        description: { type: String, default: "" },
        isSearch: { type: Boolean, default: false },
        searchPlaceholder: { type: String, default: "" },
        displayAttribute: { type: String, default: "" },
        data: { type: Array },
        isRightArrow: { type: Boolean, default: false },
        heightAuto: { type: Boolean, default: false },
        filterFunction: { type: Function },
        persistent: { type: Boolean, default: false },
        modalId: { type: String, default: "" },
        addressPlatform: { type: String, default: "" }
    },

    data() {
        return {
            filteredData: [],
            searchQuery: "",
            selectedItem: "",
            activeIndex: null,
            expandSearch: false,
        }
    },

    updated() {
        if (this.addressPlatform !== "") {
            let target = this.addressPlatform;
            let found = this.filteredData.findIndex(obj => obj.value === target);
            this.activeIndex = found;
        }
    },

    computed: {
        isShow() {
            return this.dialog;
        }
    },

    watch: {
        data: {
            handler() {
                this.filteredData = this.data;
            }
        },

        searchQuery: {
            handler() {
                this.filter(this.searchQuery);
            }
        }
    },

    methods: {
        hideModal() {
            this.$emit("close");
            this.clearText();
        },

        filter(text) {
            let filtered = this.data;
            let others = this.data.slice(-1)
            let result = this.filterFunction(filtered, text);

            if (result.length == 0) {
                this.filteredData = others
            } else {
                this.filteredData = result;
            }
        },

        clearText() {
            this.searchQuery = "";
            this.focusInput();
        },

        selectItem(item, index) {
            this.selectedItem = item;
            this.activeIndex = index;
        },

        focusInput() {
            this.expandSearch = true;
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus();
                }
            });
        },

        confirmSelection() {
            if (this.selectedItem) {
                this.$emit('action', this.selectedItem)
                this.hideModal();
            }
        }
    }
}
</script>

<style>
.checkbox-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.checkbox-group label {
    position: relative;
    cursor: pointer;
}

.checkbox-group label:before {
    content: '';
    background-color: transparent;
    border: 2px solid #BBC5CF;
    border-radius: 100%;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin: 0 15px 3px 0;
    transform: scale(0.8);
}

.checkbox-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 9px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-group input:checked+label:before {
    border: solid #315FE8;
    background: #315FE8;
    padding: 9px;
}
</style>