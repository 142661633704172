<template>
    <div class="input-outer-container" id="invest-input" :class="disabled ? 'disabled' : ''">
        <div class="input-label" v-if="label != ''">
            <div>{{ label }}</div>
            <button v-if="hasInfo" @click="$emit('info')">
                <InlineSvg
                    :src="require('@/assets/images/wallet/message-ques.svg')"
                    width="25px"
                />
            </button>
        </div>

        <div 
            class="input-container"
            :class="errorMessage ? 'red-bordered' : ''"
        >
            <div class="trade-coin-dropdown">
                <div
                class="trade-selected-coin"
                :class="disabled ? 'disabled' : ''"
                @click="$emit('show')"
                v-if="isList"
                >
                <div>
                    <img :src="selected.image" width="24px" />
                    <div>{{ selected.symbol }}</div>
                </div>
        
                <v-icon>mdi-chevron-down</v-icon>
                </div>
        
                <div class="trade-selected-coin" :class="disabled ? 'disabled' : ''" v-else>
                <div>
                    <img :src="selected.image" width="24px" />
                    <div>{{ selected.symbol }}</div>
                </div>
                </div>
            </div>

            <input
                ref="dcaInput"
                :id="id"
                class="input-text"
                :name="name"
                :value="modelValue"
                :placeholder="placeholder"
                :type="type"
                @focus="showCross = true"
                @blur="hideCross()"
                @keydown="insertNumber($event, decimalPlaces)"
                @keyup.enter="$emit('keyUpAction')"
                @input="handleInput"
                :disabled="disabled"
                :autocomplete="autocomplete"
                
            />

            <button @click="handleReset" class="input-close" v-show="showCross">
                <v-icon>mdi-close</v-icon>
            </button>

            <div class="input-symbol" :class="symbol && 'noBtn'" v-if="symbol">{{ symbol }}</div>

            <button 
                class="input-max-btn" 
                :disabled="!isLogin"
                @click="$emit('max')" 
                v-if="isMax"
            >
                {{ $t("Send.max") }}
            </button>
                
            <div class="input-remove-outer">
                <button
                    class="input-remove"
                    v-if="isRemovable"
                    @click="handleRemove"
                >
                    <img src="@/assets/images/modal/close-circle.png" width="22px" />
                </button>
            </div>
        </div>

        <div class="remark-text">{{ remarkText }}</div>
        <div class="error-message">{{ errorMessage }}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: { type: String, default: '' },
        index: { type: Number, default: 0},
        isList: { type: Boolean, default: false },
        id: { type: String },
        name: { type: String },
        placeholder: { type: String },
        type: { type: String, default: 'text' },
        modelValue: { type: String },
        errorMessage: { type: String, default: '' },
        remarkText: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        isMax: { type: Boolean, default: false },
        decimalPlaces: { type: Number, default: 0 },
        autocomplete: { type: String, default: 'off'},
        hasInfo: { type: Boolean, default: false },
        symbol: { type: String, default: '' },
        isMultiSelect: { type: Boolean, default: true },
        selectedCoin: { type: String, default: '' },
        tokenList: { type: Array, default: () => { return [] } },
        isRemovable: { type: Boolean, default: false }
    },

    model: {
        prop: "modelValue",
        event: "update:modelValue"
    },

    data() {
        return {
            isShow: false,
            showCross: false,
            hideCrossTimeout: "",
            selected: "",
        }
    },

    computed: {
        isLogin() {
            return this.$store.state.auth.isLoggedIn;
        }
    },

    beforeMount() {
        if(this.isMultiSelect) {
            this.selected = this.getCoinData(this.selectedCoin);
        } else {
            this.selected = this.selectedCoin;
        }
    },

    watch: {
        selectedCoin: {
            handler() {
                if(this.isMultiSelect) {
                    this.selected = this.getCoinData(this.selectedCoin);
                } else {
                    this.selected = this.selectedCoin;
                }
            },
        },  
    },

    methods: {
        getCoinData(symbol) {
           return this.tokenList.length > 0 ? this.tokenList.filter((item) => item.symbol === symbol)[0] : '';
        },

        hideCross() {
            clearTimeout(this.hideCrossTimeout);
            this.hideCrossTimeout = setTimeout(() => {
                this.showCross = false;
            }, 250); 
        },

        handleInput(event) {  
            let value = this.handleNumberInput(event, this.decimalPlaces);
            
            if(value) {
                let inputValue = this.removeZeroAmount(event.target.value);
                this.$emit('update:modelValue', inputValue);
            }
        },

        handleReset() {
            this.$emit('reset');
        },

        handleRemove() {
            this.$emit('remove', this.index);
        },
    }
}
</script>

<style>
#invest-input .input-label > div {
    margin-right: 10px;
}

#invest-input .input-label {
    display: flex;
    align-items: center;
}

#invest-input .secondary-btn {
    width: fit-content;
    padding: 0;
    margin-top: 0;
    font-weight: unset;
}

#invest-input .secondary-btn:hover {
    background: none;
}

#invest-input .input-container {
    position: relative;
    height: 65px;
}

#invest-input .input-text,
#invest-input ::placeholder {
    font-size: 24px;
    padding-left: 20px;
}

#invest-input .input-remove-outer {
    width: 10%;
}
    
#invest-input .input-max-btn {
    position: initial;
    margin-right: 10px;
    width: 10%;
}

#invest-input .remark-text {
    font-size: 14px;
}

#invest-input .input-container input:disabled {
    color: var(--header-text);
}

#invest-input.disabled .input-container {
    background-color: var(--gray20);
}

#invest-input.input-outer-container {
    margin-bottom: 0px;
}

.input-remove {
    display: flex;
    margin: auto 20px;
}

@media only screen and (max-width: 768px) {
    #invest-input .input-text,
    #invest-input ::placeholder {
        font-size: 18px;
    }
}

  #invest-input .trade-coin-dropdown > .trade-selected-coin {
    border-right: 1px solid var(--border-color);
  }

  #invest-input .trade-coin-dropdown > .trade-selected-coin.disabled {
    background-color: var(--gray20);
  }

  #invest-input .trade-coin-dropdown > .trade-selected-coin > div {
    border-left: 0px;
  }
</style>