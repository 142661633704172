// Styles
import '../../public/materialdesignicons.min.css'
import 'vuetify/styles'
import "vuetify/dist/vuetify.min.css";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import CustomLeftIcon from "@/assets/images/custom_icon/CustomLeftIcon.vue";
import CustomRightIcon from "@/assets/images/custom_icon/CustomRightIcon.vue";
import CustomRewardIcon from "@/assets/images/custom_icon/RewardIcon.vue";

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(({
    components,
    directives,
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides,
    icons: {
        values:{
        "custom-left-icon": {
            component: CustomLeftIcon,
        },
        "custom-right-icon": {
            component: CustomRightIcon,
        },
        "custom-reward-icon": { 
            component: CustomRewardIcon, 
        },
    },
},
}))
