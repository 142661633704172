import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store";
import { app } from "@/main";
const routes = [
  {
    path: "/signup",
    name: "SignUpView",
    component: () => import("@/views/Register/SignUpView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/emailverify",
    name: "EmailVerifiedView",
    component: () => import("@/views/Register/EmailVerifiedView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/login",
    name: "SignInView",
    component: () => import("@/views/Login/SignInView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/loading",
    name: "loading",
    component: () => import("@/views/Login/GoogleLoadingView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/appleloading",
    name: "AppleLoading",
    component: () => import("@/views/Login/AppleLoadingView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/verifytwofa",
    name: "VerifyTwoFa",
    component: () => import("@/views/Login/VerifyTwoFa.vue"),
    props: true,
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/bindphone",
    name: "BindPhoneNumber",
    component: () => import("@/views/Register/BindPhoneNumber.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  // {
  //   path: "/selectplatform",
  //   name: "SelectPlatform",
  //   component: () => import("@/views/Register/SelectPlatform.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     isCommon: true,
  //   },
  // },
  {
    path: "/setsecure",
    name: "SetSecureEmail",
    component: () => import("@/views/Register/SetSecureEmail.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("@/views/Login/ForgotPasswordView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/kyc",
    name: "KycView",
    component: () => import("@/views/Sumsub.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/home",
    name: "HomeView",
    component: () => import("@/views/Home/HomeView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/about",
    name: "Aboutus",
    component: () => import("@/views/Contents/AboutView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/compliance",
    name: "ComplianceGuidelines",
    component: () => import("@/views/Contents/ComplianceGuidelines.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/cookiepolicy",
    name: "CookiePolicy",
    component: () => import("@/views/Contents/CookiePolicyView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/security",
    name: "CustodyView",
    component: () => import("@/views/Contents/CustodyView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/fees",
    name: "FeesView",
    component: () => import("@/views/Contents/FeesView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/affiliate",
    name: "AffiliateView",
    component: () => import("@/views/Contents/AffiliateView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/otc",
    name: "OtcView",
    component: () => import("@/views/Contents/OtcView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/Contents/PrivacyPolicyView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/riskwarning",
    name: "RiskWarning",
    component: () => import("@/views/Contents/RiskWarning.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/travelrule",
    name: "TravelRule",
    component: () => import("@/views/Contents/TravelRule.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/legal/affiliate-programme",
    name: "RewardsPromotions",
    component: () => import("@/views/Contents/RewardsPromotions.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/termsofuse",
    name: "TermsOfUse",
    component: () => import("@/views/Contents/TermsOfServiceView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/termsofuse/auto-invest",
    name: "AutoInvestTerms",
    component: () => import("@/views/Contents/AutoInvestTermsView.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/",
    redirect: "/home",
  },
  // Payment Gateway
  {
    path: "/payment",
    name: "PaymentView",
    component: () => import("@/views/Payment/0_PaymentView.vue"),
    meta: {
      isCommon: true,
      requires2FA: true,
    },
  },
  // Profile
  {
    path: "/profile/setting/language",
    name: "LanguageView",
    component: () => import("@/views/Profile/LanguageView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/setting/communication",
    name: "CommunicationView",
    component: () => import("@/views/Profile/CommunicationView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/security/password",
    name: "ChangePasswordView",
    component: () => import("@/views/Profile/ChangePasswordView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/security/createapikey",
    name: "CreateApiKeyView",
    component: () => import("@/views/Profile/CreateApiKeyView.vue"),
    meta: {
      requiresAuth: true,
      requiresKyc: true,
      requires2FA: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/security/apikey",
    name: "ApiKeyView",
    component: () => import("@/views/Profile/ApiKeyView.vue"),
    meta: {
      requiresAuth: true,
      requiresKyc: true,
      requires2FA: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/security/twofa",
    name: "TwoFaView",
    component: () => import("@/views/Profile/TwoFaView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/notification",
    name: "NotificationView",
    component: () => import("@/views/Profile/NotificationView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/setting",
    name: "SettingView",
    component: () => import("@/views/Profile/SettingView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/security",
    name: "SecurityView",
    component: () => import("@/views/Profile/SecurityView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  {
    path: "/profile/accountdetails",
    name: "ProfileAccountDetails",
    component: () => import("@/views/Profile/AccountDetailsView.vue"),
    meta: {
      requiresAuth: true,
      isCommon: true,
    },
  },
  // {
  //   path: "/profile/passkeys",
  //   name: "ProfilePassKeys",
  //   component: () => import("@/views/Profile/PassKeysView.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     isCommon: true,
  //   },
  // },
  {
    path: "/announcements",
    name: "AnnouncementView",
    component: () => import("@/views/Contents/AnnouncementView.vue"),
    meta: {
      isCommon: true,
    },
  },
  // {
  //   path: "/testing",
  //   name: "TestView",
  //   component: () => import("@/views/Testing.vue"),
  //   meta: {
  //     isCommon: true,
  //   },
  // },
  {
    path: "/global",
    name: "Global",
    component: () => import("@/views/Parents/GB.vue"),
    redirect: { name: "HomeView" },
    children: [
      {
        path: "home",
        name: "GB-GlobalView",
        component: () => import("@/views/GB/Home/GlobalView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "market",
        name: "GB-MarketView",
        component: () => import("@/views/GB/Home/MarketView.vue"),
      },
      {
        path: "businessKyc",
        name: "GB-BusinessKyc",
        component: () => import("@/views/GB/BusinessKyc/0_MainKyc.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile",
        name: "GB-ProfileView",
        component: () => import("@/views/GB/Profile/ProfileView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile/platform",
        name: "GB-PlatformView",
        component: () => import("@/views/GB/Profile/PlatformView.vue"),
      },
      {
        path: "profile/identification",
        name: "GB-ProfileIdentification",
        component: () => import("@/views/GB/Profile/IdentificationView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile/reward",
        name: "GB-RewardView",
        component: () => import("@/views/GB/Profile/RewardView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "profile/affiliatestep",
        name: "GB-AffiliateStepView",
        component: () => import("@/views/GB/Profile/AffiliateStepView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "wallet",
        name: "GB-Wallet",
        component: () => import("@/views/GB/Wallet/WalletView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "wallet/history",
        name: "GB-TransactionHistory",
        component: () =>
          import("@/views/GB/Wallet/TransactionHistory/TransactionHistory.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "cryptowallet/:symbol",
        name: "GB-CryptoWalletDetail",
        component: () =>
          import("@/views/GB/Wallet/Cryptocurrency/CryptocurrencyView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "fiatwallet/:symbol",
        name: "GB-FiatWalletDetail",
        component: () => import("@/views/GB/Wallet/Fiat/FiatView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "addnewaddress/:symbol",
        name: "GB-AddNewAddress",
        component: () =>
          import("@/views/GB/Wallet/Cryptocurrency/AddNewAddress.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "sendmethod/:symbol",
        name: "GB-SendMethodView",
        component: () =>
          import("@/views/GB/Wallet/Cryptocurrency/SendMethodView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      // {
      //   path: "send/:symbol",
      //   name: "GB-SendCrypto",
      //   component: () =>
      //     import("@/views/GB/Wallet/Cryptocurrency/SendCrypto.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     requiresKyc: true,
      //     requires2FA: true,
      //   },
      // },
      // {
      //   path: "addressbook/:symbol",
      //   name: "GB-AddressBook",
      //   component: () =>
      //     import("@/views/GB/Wallet/Cryptocurrency/AddressBook.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     requiresKyc: true,
      //     requires2FA: true,
      //   },
      // },
      {
        path: "internalsend/:symbol",
        name: "GB-InternalSend",
        component: () =>
          import("@/views/GB/Wallet/Cryptocurrency/InternalSend.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "externalsend/:symbol",
        name: "GB-ExternalSend",
        component: () =>
          import("@/views/GB/Wallet/Cryptocurrency/ExternalSend.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "crossPlatformSend/:symbol",
        name: "GB-CrossPlatformSend",
        component: () =>
          import("@/views/GB/Wallet/Cryptocurrency/CrossPlatformSend.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "receive/:symbol",
        name: "GB-ReceiveCrypto",
        component: () =>
          import("@/views/GB/Wallet/Cryptocurrency/ReceiveCrypto.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "deposit/:symbol",
        name: "GB-DepositView",
        component: () =>
          import("@/views/GB/Wallet/Fiat/Deposit/DepositView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "withdraw/:symbol",
        name: "GB-WithdrawView",
        component: () =>
          import("@/views/GB/Wallet/Fiat/Withdraw/WithdrawView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "autoInvest",
        name: "GB-AutoInvestView",
        component: () =>
          import("@/views/GB/Trading/AutoInvest/AutoInvestView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "bank/:symbol",
        name: "GB-CreateNewBank",
        component: () =>
          import("@/views/GB/Wallet/Fiat/Withdraw/CreateNewBank.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "trade",
        name: "GB-TradeView",
        component: () => import("@/views/GB/Wallet/Trade/Trade.vue"),
        props: true,
      },
      {
        path: "exchange",
        name: "GB-ExchangeView",
        component: () => import("@/views/GB/Trading/0_ExchangeView.vue"),
      },
    ],
  },
  //MALAYSIA PATH
  {
    path: "/my",
    name: "Malaysia",
    component: () => import("@/views/Parents/MY.vue"),
    redirect: { name: "HomeView" },
    children: [
      {
        path: "home",
        name: "MY-GlobalView",
        component: () => import("@/views/MY/Home/GlobalView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "market",
        name: "MY-MarketView",
        component: () => import("@/views/MY/Home/MarketView.vue"),
      },
      // {
      //   path: "businessKyc",
      //   name: "MY-BusinessKyc",
      //   component: () => import("@/views/MY/BusinessKyc/0_MainKyc.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: "profile",
        name: "MY-ProfileView",
        component: () => import("@/views/MY/Profile/ProfileView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile/platform",
        name: "MY-PlatformView",
        component: () => import("@/views/MY/Profile/PlatformView.vue"),
      },
      {
        path: "profile/identification",
        name: "MY-ProfileIdentification",
        component: () => import("@/views/MY/Profile/IdentificationView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "profile/rewardstep",
      //   name: "MY-RewardStepView",
      //   component: () => import("@/views/MY/Profile/RewardStepView.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     requiresKyc: true,
      //   },
      // },
      {
        path: "profile/reward",
        name: "MY-RewardView",
        component: () => import("@/views/MY/Profile/RewardView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "profile/affiliatestep",
        name: "MY-AffiliateStepView",
        component: () => import("@/views/MY/Profile/AffiliateStepView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "wallet",
        name: "MY-Wallet",
        component: () => import("@/views/MY/Wallet/WalletView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "wallet/history",
        name: "MY-TransactionHistory",
        component: () =>
          import("@/views/MY/Wallet/TransactionHistory/TransactionHistory.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "cryptowallet/:symbol",
        name: "MY-CryptoWalletDetail",
        component: () =>
          import("@/views/MY/Wallet/Cryptocurrency/CryptocurrencyView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "fiatwallet/:symbol",
        name: "MY-FiatWalletDetail",
        component: () => import("@/views/MY/Wallet/Fiat/FiatView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "addnewaddress/:symbol",
        name: "MY-AddNewAddress",
        component: () =>
          import("@/views/MY/Wallet/Cryptocurrency/AddNewAddress.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "sendmethod/:symbol",
        name: "MY-SendMethodView",
        component: () =>
          import("@/views/MY/Wallet/Cryptocurrency/SendMethodView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      // {
      //   path: "send/:symbol",
      //   name: "MY-SendCrypto",
      //   component: () =>
      //     import("@/views/MY/Wallet/Cryptocurrency/SendCrypto.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     requiresKyc: true,
      //     requires2FA: true,
      //   },
      // },
      // {
      //   path: "addressbook/:symbol",
      //   name: "MY-AddressBook",
      //   component: () =>
      //     import("@/views/MY/Wallet/Cryptocurrency/AddressBook.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     requiresKyc: true,
      //     requires2FA: true,
      //   },
      // },
      {
        path: "internalsend/:symbol",
        name: "MY-InternalSend",
        component: () =>
          import("@/views/MY/Wallet/Cryptocurrency/InternalSend.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "externalsend/:symbol",
        name: "MY-ExternalSend",
        component: () =>
          import("@/views/MY/Wallet/Cryptocurrency/ExternalSend.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "crossPlatformSend/:symbol",
        name: "MY-CrossPlatformSend",
        component: () =>
          import("@/views/MY/Wallet/Cryptocurrency/CrossPlatformSend.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "receive/:symbol",
        name: "MY-ReceiveCrypto",
        component: () =>
          import("@/views/MY/Wallet/Cryptocurrency/ReceiveCrypto.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "deposit/:symbol",
        name: "MY-DepositView",
        component: () =>
          import("@/views/MY/Wallet/Fiat/Deposit/DepositView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
        },
      },
      {
        path: "withdraw/:symbol",
        name: "MY-WithdrawView",
        component: () =>
          import("@/views/MY/Wallet/Fiat/Withdraw/WithdrawView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "withdraw/bank/add",
        name: "MY-WithdrawAddBankView",
        component: () =>
          import("@/views/MY/Wallet/Fiat/Withdraw/AddBankView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "autoInvest",
        name: "MY-AutoInvestView",
        component: () =>
          import("@/views/MY/Trading/AutoInvest/AutoInvestView.vue"),
        meta: {
          requiresAuth: true,
          requiresKyc: true,
          requires2FA: true,
        },
      },
      {
        path: "trade",
        name: "MY-TradeView",
        component: () => import("@/views/MY/Wallet/Trade/Trade.vue"),
        props: true,
      },
      {
        path: "exchange",
        name: "MY-ExchangeView",
        component: () => import("@/views/MY/Trading/0_ExchangeView.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/maintenance",
    name: "UnderMaintenance",
    component: () => import("@/views/UnderMaintenance.vue"),
    meta: {
      isCommon: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

["push", "replace"].forEach((method) => {
  const originalMethod = router[method];
  router[method] = function m(location) {
    return originalMethod.call(this, location).catch((error) => {
      console.log(error);
    });
  };
});

router.beforeEach((to, from, next) => {
  let isLogin = localStorage.getItem("jwt_key");
  if (to.meta.requiresAuth && !isLogin) {
    return next({ name: "SignInView" });
  }

  if (isLogin && (to.name === "SignInView" || to.name === "SignUpView")) {
    return next({ name: "HomeView" });
  }

  if (isLogin) {
    let user = store.state.user;
    let preferences = store.state.preferences;

    // APPROVED USER CANNOT REVERT BACK KYC PAGE & SELECT PLATFORM
    if (
      (user.kyc_status === "approved" &&
        user.platform != "0" &&
        to.name === "KycView") ||
      (user.kyc_status === "approved" && to.name === "GB-BusinessKyc")
    ) {
      return next({ name: `HomeView` });
    }
    if (to.name === "OtcView" && preferences.platform != "2") {
      return next({ name: "notFound" });
    }

    if (user.business) {
      if (user.secure_email === "" && to.name !== "SetSecureEmail") {
        return next({ name: "SetSecureEmail" });
      }

      if (
        user.secure_email &&
        user.phone === "" &&
        to.name !== "BindPhoneNumber"
      ) {
        return next({ name: "BindPhoneNumber" });
      } else if (user.phone !== "") {
        if (user.kyc_status === "incomplete") {
          // IF NO SELECT PLATFORM, PREFERENCES.PLATFORM = 0
          if (preferences.platform == "0" && to.name !== "GB-BusinessKyc") {
            return next({ name: "GB-BusinessKyc" });
          } else if (
            user.platform == "0" &&
            preferences.platform != "0" &&
            to.name !== "GB-BusinessKyc"
          ) {
            return next(false);
          }
        }
      }
    } else {
      if (user.phone === "" && to.name !== "BindPhoneNumber") {
        return next({ name: "BindPhoneNumber" });
      } else if (user.phone !== "") {
        if (user.kyc_status !== "approved") {
          if (!user.business && to.name !== "KycView") {
            return next({ name: "KycView" });
          }
        } else if (user.platform == "0") {
          if (!user.business && to.name !== "KycView") {
            return next({ name: "KycView" });
          }
        }
      }
    }

    let currentPlat = preferences.platform == 1 ? "MY" : "GB";
    if (!to.meta.isCommon && !to.name.includes(currentPlat)) {
      if (currentPlat == "GB") {
        store.dispatch("user/showSwitchErrorModal", true);
        return next({
          name: to.name.replace("MY", currentPlat),
          params: to.params,
        });
      } else {
        store.dispatch("user/showSwitchErrorModal", true);
        return next({
          name: to.name.replace("GB", currentPlat),
          params: to.params,
        });
      }
    }
  } else {
    if (to.name.includes("MY-")) {
      if (to.name.includes("ExchangeView")) {
        if (to.name.includes("GB-MY-")) {
          return next({ name: to.name.replace("GB-", "") });
        }
      } else {
        return next({ name: `HomeView` });
      }
    }
  }
  if (
    to.name !== "SignInView" &&
    to.name !== "loading" &&
    to.name !== "AppleLoading" &&
    to.name !== "VerifyTwoFa"
  ) {
    if (localStorage.getItem("request_id")) {
      localStorage.removeItem("request_id");
    }
  } else if (to.name == "SignInView" && !to.query.id) {
    if (localStorage.getItem("request_id")) {
      localStorage.removeItem("request_id");
    }
  }
  return next();
});

router.afterEach(() => {
  setTimeout(function () {
    window.scrollTo(0, 0);
  }, 20);
});

export default router;
