<template>
  <div class="input-outer-container" id="h-phone-input">
    <div class="input-label" v-if="label != ''">{{ label }}</div>

    <div class="input-container" :class="errorMessage ? 'red-bordered' : ''">
      <vue-tel-input
        v-model="phoneNumber"
        ref="telInput"
        mode="international"
        :validCharactersOnly="true"
        :preferredCountries="['MY', 'SG']"
        :ignoredCountries="['CU', 'IR', 'KP', 'SY', 'US']"
        :defaultCountry="defaultCountry"
        :autoFormat="false"
        :no-example="true"
        :dropdownOptions="{
          showFlags: false,
          showDialCodeInSelection: true,
          showDialCodeInList: true,
          showSearchBox: false,
        }"
        :inputOptions="{
          placeholder: placeholder,
          showDialCode: false,
          maxlength: maxLength
        }"
        :disabled="disabled"
      >
        <slot name="arrow-icon">mdi-minus</slot>
      </vue-tel-input>
    </div>

    <div class="remark-text" v-if="remarkText">{{ remarkText }}</div>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
import CountryList from "country-list-with-dial-code-and-flag";

export default {
  props: {
    label: { type: String, default: "" },
    placeholder: { type: String },
    modelValue: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
    remarkText: { type: String },
    disabled: { type: Boolean, default: false },
    maxLength: { type: Number, default: Infinity },
  },

  mounted() {
    if (
      this.modelValue != "" &&
      this.modelValue != undefined &&
      this.modelValue != null
    ) {
      let countries = CountryList.getAll();
      let country = countries.find((c) => this.modelValue.includes(c.dialCode));
      this.defaultCountry = country.code.toUpperCase() ?? "MY";

      if (this.modelValue.includes(country.dialCode)) {
        this.updatePhoneNumber(this.modelValue.replace(country.dialCode, ""));
      }
    }
  },

  data() {
    return {
      defaultCountry: "MY",
      placeholderLabel: this.placeholder,
      phoneNumber: "",
    };
  },

  watch: {
    phoneNumber: {
      handler() {
        this.$emit("update:modelValue", this.$refs.telInput.phoneObject.number);
      },
    },
  },
};
</script>

<style>
#h-phone-input .input-tel.is-focused .input-tel__input,
#h-phone-input .input-country-selector.is-focused .country-selector__input {
  box-shadow: none !important;
}

#h-phone-input .vue-phone-number-input .select-country-container {
  width: 85px !important;
  min-width: 85px !important;
  flex: 0 0 85px !important;
}

#h-phone-input .vti__selection .vti__country-code {
  font-size: 15px;
}

#h-phone-input .country-selector.has-hint .country-selector__input,
#h-phone-input .country-selector.has-value .country-selector__input,
#h-phone-input .input-tel.has-value .input-tel__input {
  padding-top: 0px !important;
}

#h-phone-input .country-selector__country-flag {
  top: 16px !important;
}

#h-phone-input .country-selector__toggle {
  top: calc(40% - 10px) !important;
}

#h-phone-input .country-selector.has-list-open .country-selector__toggle {
  top: calc(50% - 10px) !important;
}

#h-phone-input .country-selector__input,
#h-phone-input .input-tel__input {
  font-weight: 500 !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
}

#h-phone-input .input-tel.has-hint .input-tel__label,
#h-phone-input .input-tel.has-value .input-tel__label {
  display: none;
}

#h-phone-input .vue-tel-input {
  width: 100% !important;
  border: none;
  box-shadow: none !important;
}

#h-phone-input .vue-tel-input .vti__dropdown {
  width: 85px !important;
  min-width: 85px !important;
  flex: 0 0 85px !important;
}

#h-phone-input .vue-tel-input .vti__dropdown:hover {
  background-color: transparent;
}

#h-phone-input .vue-tel-input .vti__selection {
  justify-content: space-evenly;
  border-right: gray solid 1px;
}
</style>
