import api from "@/assets/api/api_my.js";

const state = {
    kyc_status: "incomplete"
}

const mutations = {
    SET_KYC_STATUS(state, status) {
        state.kyc_status = status;
    }
}

const actions = {
    // GET INFO
    async getBusinessKycItem() {
        let response = await api.apiCall("GET", "/identity/api/kycBusinessItem");

        if(response.status === "success") {
            return response.data;
        } else {
            console.error(response.message);
        }
    },

    async getAuthenticationInfo({ commit }) {
        let response = await api.apiCall("GET", "/identity/jwt/business/kyc");

        if(response.status === "success") {
            commit("SET_KYC_STATUS", (response.data.KycStatus).toLowerCase());
            return response.data;
        } else {
            console.error(response.message);
        }
    },

    // 1. BUSINESS TYPE
    async submitAddType({ commit, dispatch }, params) {
        let response = await api.apiCall("POST", "/identity/jwt/business/kyc/type", params);

        if(response.status !== "success") {
            throw response;
        } else {
            dispatch("preferences/setPlatform", 1, { root: true });
        }
    },

    // 2. BUSINESS DETAILS
    async submitAddDetail(context, params) {
        let response = await api.apiCall("POST", "/identity/jwt/business/kyc/detail", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    // 3. BUSINESS REPRESENTATIVE
    async submitAddRepresentative(context, params) {
        let response = await api.apiCall("POST", "/identity/jwt/business/kyc/represent", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    // 4. BUSINESS OWNERS
    async editBusinessOwner(context, params) {
        let response = await api.apiCall("PUT", "/identity/jwt/business/kyc/owner", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    async deleteBusinessOwner(context, params) {
        let response = await api.apiCall("DELETE", "/identity/jwt/business/kyc/owner", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    async addBusinessOwner(context, params) {
        let response = await api.apiCall("POST", "/identity/jwt/business/kyc/owner", params);
          
        if(response.status === "success") {
            return response.data;
        } else {
            throw response;
        }
    },

    // 5. SOURCE DECLARATION
    async submitAddSource(context, params) {
        let response = await api.apiCall("POST", "/identity/jwt/business/kyc/source", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    // 6. DOCUMENTATION
    async uploadAttachment(context, params) {
        let response = await api.apiCall("POST", "/identity/jwt/users/kyc/attachment", params);

        if (response.status === "success") {
            return response.data;
        } else {
            throw response;
        }
    },

    async deleteAttachment(context, params) {
      let response = await api.apiCall("DELETE", "/identity/jwt/business/kyc/attachment", params);
  
      if (response.status === "success") {
        return response.data;
      } else {
          throw response;
      }
    },

    // FINISH
    async confirm(context, params) {
        let response = await api.apiCall(
            "POST",
            "/identity/jwt/business/kyc/submit",
            params
          );

        if(response.status !== "success") {
            throw response;
        }
    }
}

export const MY_businesskyc = {
    namespaced: true,
    state,
    mutations, 
    actions
}