<template>
    <v-dialog
        v-model="isShow"
        class="dialog-modal"
        max-width="500px"
        :persistent="persistent"
        @click:outside="$emit('close')"
    >
        <div class="modal-body-custom" id="modal-select-crypto">
            <button
                @click="$emit('close')"
                class="modal-close-btn"
            >
                <img src="@/assets/images/modal/close-circle.png" width="22px"/>
            </button>

            <div class="modal-title">{{ title }}</div>
            <div class="modal-description">{{ description }}</div>

            <div class="modal-search-bar">
                <img src="@/assets/images/wallet/search-icon.svg" width="25px" />

                <input 
                    name="search"
                    v-model="searchQuery"
                    class="modal-search-input"
                    :placeholder= "$t('Button.Search')"
                />

                <button @click="clearText" class="input-close" v-if="searchQuery">
                    <v-icon>mdi-close</v-icon>
                </button>
            </div>
            <div class="modal-crypto-header">
                <div class="modal-crypto-header-name">Cryptocurrency</div>
                <div class="modal-crypto-header-price" v-if="status === 'buy'">{{$t('Trading.price')}} ({{ $store.state.preferences.platform == 1 ? "MYR" : "USD" }})</div>
                <div class="modal-crypto-header-price" v-else-if="status === 'sell'">{{$t('Trading.avlbAmt')}}</div>
            </div>

            <div class="modal-crypto-content" v-if="filteredCrypto.length > 0">
                <div 
                    class="modal-crypto-row"
                    v-for="(item, index) in filteredCrypto"
                    :key="index"
                    @click="modalType === 'SR' ? isSend ? navigateToRoute('SendMethodView', { symbol: item.symbol }) : $emit('action', item.symbol) : $emit('action', item.symbol)"
                >
                    <div class="modal-crypto-inner-row">
                        <img :src="item.image" :alt="item.symbol" />
                        <div class="modal-crypto-name">
                            {{ item.symbol }}
                        </div>
                    </div>

                    <div class="modal-crypto-bal-container">
                        <div class="modal-crypto-balance" v-if="status === 'sell'">
                            <span 
                                v-if="item.available > 1000000 && deviceWidth < 512"
                                class="text-uppercase"
                            >
                                {{ $NumAbbr(item.available, 2) }}
                            </span>
                            <span v-else>{{ numberSeparator(parseFloat(item.available), item.disp_qty_scale) }}</span>
                        </div>
                        <div class="modal-crypto-balance" v-else-if="status === 'buy'">
                            <span 
                                v-if="item.available_in_quote > 1000000 && deviceWidth < 512"
                                class="text-uppercase"
                            >
                                {{ $NumAbbr(item.available_in_quote, 2) }}
                            </span>
                            <span v-else>{{ numberSeparator(parseFloat(item.available_in_quote), 2) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-crypto-content" v-else>
                <div class="main-remark-text w-100">
                    {{ 
                        filteredCrypto.length == 0 && searchQuery != "" ?
                        `${$t("wallet.noDataFor")} "${searchQuery}"` :
                        $t("Trading.noData") 
                    }}
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialog: { type: Boolean },
        title: { type: String },
        description: { type: String },
        isSend: { type: Boolean, default: false },
        cryptocurrency: { type: Array },
        modalType: { type: String },
        persistent: { type: Boolean, default: false },
        status: { type: String }
    },

    data () {
        return {
            filteredCrypto: [],
            searchQuery: "",
        }
    },

    computed: {
        deviceWidth() {
            return this.$store.state.preferences.deviceWidth;
        },

        isShow() {
            return this.dialog;
        }
    },

    watch: {
        cryptocurrency: {
            handler() {
                this.filteredCrypto = this.cryptocurrency;
            }
        },

        searchQuery: {
            handler() {
                this.filter(this.searchQuery);
            }
        }
    },

    methods: {
        filter(text) {
            let filtered = this.cryptocurrency;

            this.filteredCrypto = filtered.filter((el) => {
                let nameMatch = el.name.toLowerCase().includes(text.toLowerCase());
                let symbolMatch = el.symbol.toLowerCase().includes(text.toLowerCase());

                return nameMatch || symbolMatch;
            });
        },

        clearText() {
            this.searchQuery = "";
        }
    }  
};
</script>

<style>
.dialog-modal .v-overlay__content {
  border-radius: 0.5rem !important;
  background-color: var(--gray10);
  padding: 1rem;
}

</style>