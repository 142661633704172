<template>
  <div class="input-outer-container">
        <div class="input-label" v-if="label != ''">
            {{ label }}
            <span class="optional-label" v-if="optional">*{{ $t("BusinessKyc.optional") }}</span>
        </div>

        <div 
            class="textarea-container"
            :class="errorMessage ? 'red-bordered' : ''"
        >
            <textarea
                class="input-text"
                :name="name"
                :value="modelValue"
                :placeholder="placeholder"
                :type="type"
                @focus="showCross = true"
                @blur="hideCross()"
                @keyup.enter="$emit('keyUpAction')"
                @input="handleInput"
                rows="5"
                cols="50"
                :disabled="disabled"
            ></textarea>

            <button @click="$emit('reset')" class="input-close" v-show="showCross">
                <v-icon>mdi-close</v-icon>
            </button>
        </div>

        <div class="remark-text" v-if="remarkText">{{ remarkText }}</div>
        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { kMaxLength } from 'buffer';

export default {
  props: {
    label: { type: String, default: "" },
    name: { type: String },
    placeholder: { type: String },
    type: { type: String, default: "text" },
    modelValue: { type: String },
    errorMessage: { type: String, default: "" },
    remarkText: { type: String },
    disabled: { type: Boolean, default: false },
    optional: { type: Boolean, default: false },
    maxLength: { type: Number, default: Infinity },
  },

  data() {
    return {
      showCross: false,
      hideCrossTimeout: "",
    };
  },

  methods: {
        hideCross() {
            clearTimeout(this.hideCrossTimeout);
            this.hideCrossTimeout = setTimeout(() => {
                this.showCross = false;
            }, 250); 
        },

        handleInput(event) {
            this.$emit("clearError");
            if((event.target.value).length >= this.maxLength) {
                event.target.value = event.target.value.slice(0, this.maxLength);
            }
            
            this.$emit("update:modelValue", event.target.value);
        },
    }
}
</script>

<style></style>
