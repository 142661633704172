<template>
  <div class="country-select-outer">
    <div class="input-label" v-if="label">{{ label }}</div>

    <div
      class="form-select custom-select"
      :class="[disabled && 'hidearrow', errorMessage ? 'red-bordered' : '']"
      ref="select"
    >
      <div
        class="selected-option"
        :class="isCapitalize ? 'text-capitalize' : ''"
        @click="toggleOptions"
        v-if="!disabled"
      >
        <span :class="{ 'gray90-text': selectedOption.label === defaultOption.label }">
          {{ selectedOption.label }}
        </span>
      </div>

      <div
        class="disabled-selected-option"
        :class="isCapitalize ? 'text-capitalize' : ''"
        v-else
      >
        {{ selectedOption.label }}
      </div>

      <div class="options" v-if="isOpen">
          <div
              class="option"
              :class="isCapitalize ? 'text-capitalize':'' "
              v-for="option in options"
              :key="option.value"
              @click="changeOption(option)"
          >
              {{ option.label }}
          </div>
      </div>
    </div>

    <div class="remark-text" v-if="remarkText">{{ remarkText }}</div>
    <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String, default: "" },
    defaultOptionText: { type: String, default: "" },
    remarkText: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
    assignedData: { type: Array, default: () => [] },
    selectedData: { type: String, default: "" },
    isCapitalize: { type: Boolean, default: true },
  },

  data() {
    return {
      isOpen: false,
      selectedOption: "",
      options: this.assignedData,
    };
  },

  computed: {
    defaultOption() {
      let defaultText =
        this.defaultOptionText === ""
          ? this.$t("BusinessKyc.plsSelect")
          : this.defaultOptionText;
      return { value: "", label: defaultText };
    },
  },

  mounted() {
    this.options = this.assignedData;

    if (this.selectedData == "") {
      this.changeOption(this.defaultOption);
    } else {
      let selectedObjectData = this.options.filter((option) => {
        return option.value.toLowerCase() == this.selectedData.toLowerCase();
      });
      if (selectedObjectData.length > 0) {
        this.changeOption(selectedObjectData[0]);
      } else {
        this.changeOption(this.defaultOption);
      }
    }

    this.$refs.select.focus();
  },

  watch: {
    assignedData: {
      handler() {
        this.options = this.assignedData;
      },
    },

    selectedData: {
      handler() {
        if (this.selectedData == "") {
          this.changeOption(this.defaultOption);
        } else {
          let selectedObjectData = this.options.filter((option) => {
            return option.value.toLowerCase() == this.selectedData.toLowerCase();
          });
          if (selectedObjectData.length > 0) {
            this.changeOption(selectedObjectData[0]);
          } else {
            this.changeOption(this.defaultOption);
          }
        }
      },
    },

    selectedOption: {
      handler() {
        if (this.selectedOption == "") {
          this.selectedOption = this.defaultOption;
        }
      },
    },
  },

  methods: {
    toggleOptions() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.addEventListener("click", this.closeOptions);
      } else {
        document.removeEventListener("click", this.closeOptions);
      }
    },

    closeOptions(event) {
      if (this.$refs.select) {
        if (!this.$refs.select.contains(event.target)) {
          this.isOpen = false;
          document.removeEventListener("click", this.closeOptions);
        }
      }
    },

    changeOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit("select", option);
      document.removeEventListener("click", this.closeOptions);
    },
  },
};
</script>

<style></style>
