<template>
    <v-dialog v-model="isShow" class="dialog-modal" max-width="510px" :persistent="persistent"
        @click:outside="isDelete ? '' : $emit('close')">
        <div class="modal-body-custom" id="modal-select-address">
            <button @click="$emit('close')" class="modal-close-btn">
                <img src="@/assets/images/modal/close-circle.png" width="22px" />
            </button>

            <div class="modal-title">{{ title }}</div>
            <div class="modal-description">{{ description }}</div>

            <div class="modal-search-bar">
                <InlineSvg :src="require('@/assets/images/wallet/search-icon.svg')" width="25px" @click="focusInput"
                    v-if="!isSearchExpanded" />

                <input name="search" ref="searchInput" v-model="searchQuery" :placeholder="$t('Address.searchAddress')"
                    class="modal-search-input" :class="isSearchExpanded ? 'searchShow bg-gray20' : ''"
                    @blur="!searchQuery ? isSearchExpanded = modelValue ? true : false : ''" autocomplete="off"/>

                <button @click="clearText" class="input-close bg-gray20" v-if="searchQuery && isSearchExpanded">
                    <v-icon>mdi-close</v-icon>
                </button>
            </div>

            <div class="modal-address-content" v-if="filteredAddress.length > 0">
                <NavigationButton v-for="item, index in address" :key="index" icon="modal/address-icon.png" :iconSize=35 :title="item.label" :subtitle="item.address"
                    :tag="item.tag" :network="`${item.destination_name} - ${item.network_name}`"
                    @action="setAddress(item)" :isRightArrow="false" :isCloseBtn="true" @delete="deleteAddress(item)" />

            </div>

            <div class="modal-address-content no-address" v-else>
                <img src="@/assets/images/modal/no-address.png" width="80" class="align-self-center">

                <div class="main-remark-text w-100">
                    {{
                        filteredAddress.length == 0 && searchQuery != "" ?
                            `${$t("Address.noAddressFound")} "${searchQuery}"` :
                            $t("Address.noAddress")

                    }}
                </div>
            </div>

            <button class="tertiary-btn add-address" @click="navigateToRoute('AddNewAddress', { symbol: symbol })">
                <div>
                    <v-icon>mdi-plus</v-icon>
                    {{ $t("Address.addNewAddress") }}
                </div>
            </button>

            <IconTextModal :dialog="isDelete" image="modal/warning.png" :title="$t('Address.deleteAddress')"
                :description="$t('Address.deleteAddressMsg')" titleClass="fail-text"
                :buttonText="$t('Button.confirm')" @action="confirmDelete" :isCloseBtn="true" maxWidth="450px"
                @close="isDelete = false" />

        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialog: { type: Boolean },
        title: { type: String },
        description: { type: String },
        isSend: { type: Boolean, default: false },
        address: { type: Array },
        persistent: { type: Boolean, default: false },
        status: { type: String }
    },

    data() {
        return {
            filteredAddress: [],
            searchQuery: "",
            selectedAddress: {},
            isDelete: false,
            id: "",
            isSearchExpanded: false
        }
    },

    computed: {
        deviceWidth() {
            return this.$store.state.preferences.deviceWidth;
        },

        isShow() {
            return this.dialog;
        }
    },

    watch: {
        address: {
            handler() {
                this.filteredAddress = this.address;
            }
        },

        searchQuery: {
            handler() {
                this.filter(this.searchQuery);
            }
        }
    },

    methods: {
        filter(text) {
            let filtered = this.address;

            this.filteredAddress = filtered.filter((el) => {
                let nameMatch = el.label.toLowerCase().includes(text.toLowerCase());
                let addressMatch = el.address.toLowerCase().includes(text.toLowerCase());
                let originMatch = el.destination_name.toLowerCase().includes(text.toLowerCase());

                return nameMatch || addressMatch || originMatch;
            });
        },


        clearText() {
            this.searchQuery = "";
            this.focusInput();
        },
        setAddress(address) {
            if (this.isDelete === false) {
                this.selectedAddress = address;
                this.$emit('setAddress', this.selectedAddress)
            }
        },
        deleteAddress(address) {
            this.isDelete = true;
            this.id = address.id;
        },
        confirmDelete() {
            let params = {
                id: parseInt(this.id),
            };
            if(this.$store.getters["preferences/routePrefix"] === "MY"){
                this.$store
                .dispatch("MY_wallet/deleteAddress", params)
                .then(() => {
                    this.$emit('refreshAddress')
                    this.isLoading = true;
                })
                .catch((error) => {
                    console.error("Failed to delete address:", error);
                });
            this.isDelete = false;
            } else{
                this.$store
                .dispatch("wallet/deleteAddress", params)
                .then(() => {
                    this.$emit('refreshAddress')
                    this.isLoading = true;
                })
                .catch((error) => {
                    console.error("Failed to delete address:", error);
                });
            this.isDelete = false;
            }

        },
        focusInput() {
            this.isSearchExpanded = true;
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus();
                }
            });
        },
    }
};
</script>

<style>
.dialog-modal .v-overlay__content {
    border-radius: 0.5rem !important;
    background-color: var(--gray10);
    padding: 1rem;
}

#modal-select-address .navigation-btn-content {
    align-items: start;
}

#modal-select-address .main-remark-text{
    font-size: 14px;
    color: var(--gray90)
}

.add-address {
    width: 100%;
}

.modal-address-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto;
}

.modal-address-content.no-address {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 2rem;
}

#modal-select-address .navigation-btn-container .tertiary-btn {
    background: none;
    width: 100%;
}
</style>