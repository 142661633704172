<template>
  <div class="country-select-outer">
    <div class="input-label" v-if="label">{{ label }}</div>

    <div
        class="form-select custom-select"
        :class="[disabled && 'hidearrow', errorMessage ? 'red-bordered' : '']"
        ref="select"
    >
        <div class="selected-option" @click="toggleOptions" v-if="!disabled">
            <span :class="{'gray90-text': selectedOption.countryName === defaultOption.countryName}">
                {{ selectedOption.countryName }}
            </span>
        </div>

        <div class="disabled-selected-option" v-else>
            {{ selectedOption.countryName }}
        </div>

        <div class="options" v-if="isOpen">
            <div class="select-search-outer">
            <img
                src="@/assets/images/wallet/search-icon.svg"
                class="search-icon"
                width="22"
                height="20"
            />
            <input
                type="text"
                v-model="search"
                :placeholder="$t('Profile.searchCountry')"
                @blur="closeOptions"
                class="select-search-input"
                ref="search"
            />
            </div>

            <div
            class="option"
            v-if="selectedOption === ''"
            @click="changeOption(defaultOption)"
            >
            {{ $t("Kyc.PlsSelectCountry") }}
            </div>
            <div
            class="option"
            v-for="option in filteredData"
            :key="option.countryShortCode"
            @click="changeOption(option)"
            >
            {{ option.countryName }}
            </div>
        </div>
        </div>

        <div class="remark-text" v-if="remarkText">{{ remarkText }}</div>
        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
</template>

<script>
import countryList from "../../../public/data.json";

export default {
props: {
    isShowIcon: { type: Boolean, default: true },
    label: { type: String, default: "" },
    defaultOptionText: { type: String, default: "" },
    selectedCountryCode: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    remarkText: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
    filterCountry: {type: Array, default() { return [] }},
},

    data() {
        return {
            isOpen: false,
            selectedOption: "",
            search: "",
            options: countryList,
            filteredData: [],
        };
    },

    mounted() {
        let filteredCountryList = countryList;

        if(this.filterCountry.length > 0) {
            this.filterCountry.map((country) => {
                filteredCountryList = filteredCountryList.filter((option) =>
                    !option.countryName.toLowerCase().includes(country.toLowerCase())
                );
            });
        }

        this.options = filteredCountryList;
        this.filteredData = filteredCountryList;

        this.filteredData = this.filteredData.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        if (this.selectedCountryCode == "0") {
            this.changeOption(this.defaultOption);
        } else {
            this.assignValue(this.selectedCountryCode);
        }

        this.$refs.select.focus();
    },

    computed: {
        defaultOption() {
            let defaultText =
                this.defaultOptionText === ""
                ? this.$t("Kyc.PlsSelectCountry")
                : this.defaultOptionText;
            return { countryShortCode: "0", countryName: defaultText };
        },
    },

    watch: {
        selectedCountryCode: {
            handler() {
                if (this.selectedCountryCode === "" || this.selectedCountryCode == "0") {
                this.selectedOption = this.defaultOption;
                this.changeOption(this.selectedOption);
                return;
                }

                this.assignValue(this.selectedCountryCode);
            },
        },

        search: {
            handler() {
                this.filteredOptions(this.search);
            },
        },

        isOpen: {
            handler() {
                if (this.isOpen) {
                this.$nextTick(() => {
                    this.$refs.search.focus();
                });
                }
            },
        },
    },

    methods: {
        assignValue(countryCode) {
            if (this.selectedCountryCode !== "" || this.selectedCountryCode !== "0") {
                let data = this.options;

                let selected = data.filter((option) => {
                return option.countryShortCode.toLowerCase() === countryCode.toLowerCase();
                });

                this.changeOption(selected[0]);
            }
        },

        filteredOptions(text) {
            let filtered = this.options;

            this.filteredData = filtered.filter((option) =>
                option.countryName.toLowerCase().includes(text.toLowerCase())
            );

            this.filteredData = this.filteredData.sort((a, b) => {
                    if (a.countryName < b.countryName) {
                        return -1;
                    }
                    if (a.countryName > b.countryName) {
                        return 1;
                    }
                    return 0;
                });
            },

            toggleOptions() {
                this.isOpen = !this.isOpen;
                if (this.isOpen) {
                    document.addEventListener("click", this.closeOptions);
                } else {
                    document.removeEventListener("click", this.closeOptions);
                }
            },

            closeOptions(event) {
                if (this.$refs.select) {
                    if (!this.$refs.select.contains(event.target)) {
                    this.search = "";
                    this.isOpen = false;
                    document.removeEventListener("click", this.closeOptions);
                    }
                }
            },

                changeOption(option) {
                this.selectedOption = option;
                this.search = "";
                this.isOpen = false;
                this.$emit("select", option);
                document.removeEventListener("click", this.closeOptions);
            },
        },
    };
</script>

<style>

</style>