<template>
    <div class="no-data-container">
        <img :src="require(`@/assets/images/${image}`)" width="65px" v-if="image"/>
        <img src="@/assets/images/wallet/no-transaction.png" width="65px" v-else/>
        <div>{{ message ? message : $t("Trading.noDataHistory") }}</div>
    </div>
</template>

<script>
export default {
    props:{
        message: { type: String },
        image: { type:String }
    }
}
</script>

<style>

</style>