import api from "@/assets/api/api.js";
import * as global from "@/globalFunction.js";
import { app } from "@/main";
import router from "@/router";
import VueCookies from "vue-cookies";

let methods = global.default.methods;

const state = {};

const mutations = {};

const actions = {
  //INVEST
  async getInvestFundsAvailable() {
    let response = await api.apiCall("GET", "/auto-invest/tokens/quote");
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async getInvestAvailable() {
    let response = await api.apiCall("GET", "/auto-invest/tokens");
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  //PLAN
  async getPlanList({ commit }) {
    let response = await api.apiCall("GET", "/auto-invest/plan");
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async getPlanHistory({ commit }, params) {
    let response = await api.apiCall(
      "GET",
      "/auto-invest/plan/history",
      params
    );
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async getPlanOrders({ commit }, params) {
    let response = await api.apiCall("GET", "/auto-invest/orders", params);
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  //PLAN ACTION
  async createPlan({ commit }, params) {
    let response = await api.apiCall(
      "POST",
      "/auto-invest/plan/create",
      params
    );
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async terminatePlan({ commit }, params) {
    let response = await api.apiCall(
      "POST",
      "/auto-invest/plan/terminate",
      params
    );
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  // pause/unpause params
  async updatePlan({ commit }, params) {
    let response = await api.apiCall(
      "POST",
      "/auto-invest/plan/update",
      params
    );
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },
};

export const autoInvest = {
  namespaced: true,
  state,
  mutations,
  actions,
};
