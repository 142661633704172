<template>
    <v-dialog v-model="$store.state.user.showHavingTroubleModal"
        :class="`dialog-modal ${isExchange ? 'exchange-modal' : ''}`" :isExchange="isExchange === true"
        max-width="500px">
        <div class="modal-body-custom" :id="modalId ? modalId : 'modal-having-trouble'">
            <button @click="$store.dispatch('user/showHavingTroubleModal', false)" class="modal-close-btn">
                <img src="@/assets/images/modal/close-circle.png" width="22px" />
            </button>

            <img :src="require(`@/assets/images/${image}`)" class="modal-image" width="50px" v-if="image" />


            <div class="modal-title">{{ title || defaultTitle }}</div>

            <span v-for="item, index in data" :key="index" class="w-100">
                <a class="having-trouble-item" :href="item.link" target="_blank" v-if="item.isNewTab">
                    <img :src="require(`@/assets/images/${item.img}`)" width="40px" v-if="item.img" />
                    <div>{{ item.title }}</div>
                </a>

                <button class="having-trouble-item" @click="navigateToRoute(item.link)" v-else>
                    <img :src="require(`@/assets/images/${item.img}`)" width="40px" v-if="item.img" />
                    <div>{{ item.title }}</div>
                </button>
            </span>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: { type: String },
        data: { type: Array },
        modalId: { type: String },
        isExchange: { type: Boolean, default: false },
        image: { type: String }
    },

    beforeUnmount() {
        this.$store.dispatch("user/showHavingTroubleModal", false);
    },

    computed: {
        defaultTitle() {
            return this.$t("Login.trouble");
        }
    }
}
</script>

<style>
#modal-having-trouble .modal-title {
    margin-bottom: 1rem;
    margin-top: 0;
}

#modal-having-trouble {
    padding: 20px 10px;
}

.having-trouble-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 12px 3px;
    border-bottom: 1px solid var(--gray30);
}

.having-trouble-item div {
    margin-left: 10px;
    color: var(--header-text);
    font-weight: 500;
    text-align: left;
}

.exchange-modal .having-trouble-item div {
    color: var(--trading-text) !important;
}

.having-trouble-item:hover {
    background-color: var(--gray20);
}
</style>