<template>
    <div 
        class="remark-container"
        :class="[
            fullWidth ? 'w-100' : 'w-fit-content', 
            isWarning ? 'bg-red50 fail-text' : 'bg-primary10 primary-color-text'
        ]"
        :id="remarkId"
    >
        <div class="remark-container-row">
            <div>
                <InlineSvg 
                    :src="require(`@/assets/images/${icon}`)"
                    width="20px"
                    v-if="icon"
                />
            </div>
            
            <div 
                class="remark-container-text"
                :class="isTitleBold ? 'fw-bold' : ''"
                v-if="(typeof text !== 'object') && text"
                v-html="text"
            />
                <!-- <p v-html="text"></p> -->
                <!-- {{ text }} -->
                
                <!-- <button 
                    class="remark-help-center" 
                    @click="routeToHelpCenter" 
                    v-if="isHelpCenter"
                >
                    {{ $t("Profile.helpCentre") }}.
                </button> -->
            <!-- </div> -->

            <div 
                class="remark-container-text fiat-remark-container"
                v-else-if="text"
            >
                <span class="fw-bold">{{ hasTitle && text.title }}</span>
                <li v-for="(desc, index) in text.desc" :key="index">
                    {{ desc }}
                </li>
                
                <button 
                    class="remark-help-center" 
                    @click="routeToHelpCenter" 
                    v-if="isHelpCenter"
                >
                    {{ $t("Profile.helpCentre") }}.
                </button>
            </div>
        </div>
        
        <div v-if="list">
            <ul class="remark-container-list">
                <li v-for="item, index in list" :key="index">
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fullWidth: { type: Boolean, default: true },
        remarkId: {type: String, default: ""},
        icon: { type: String, default: "" }, // SVG
        isWarning: { type: Boolean, default: false },
        text: { type: [String, Object] },
        list: { type: Array },
        isHelpCenter: { type: Boolean, default: false },
        isTitleBold: { type: Boolean, default: false }
    },

    data(){
        return{
            hasTitle: false,
        }
    },

    mounted(){
        if(this.text && typeof this.text === "object" && this.text.title !== undefined) {
            this.hasTitle = true;
        }
    },

    methods: {
        routeToHelpCenter() {
            this.openLinkInNewTab("https://support.hata.io/support/home");
        }
    }
}
</script>

<style>
@media only screen and (max-width: 768px) {
    .remark-container-list {
        padding-left: 2rem !important;
    }

    #deposit-delay .remark-container-list {
        padding-left: 2rem !important;
    }

    .remark-container{
        width: 95% !important;
    }
}
</style>