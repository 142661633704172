import api from "@/assets/api/api.js";
import * as global from "@/globalFunction.js";

let methods = global.default.methods;

import { app } from "@/main";

const state = {
  isLoggedIn: false,
  userPropsData: [],
};

const mutations = {
  SET_AUTH(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },

  SET_USER_PROPS_DATA(state, data) {
    state.userPropsData = data;
  },
};

const actions = {
  initializeAuthState({ commit }) {
    const isLoggedIn = localStorage.getItem("jwt_key") ? true : false;
    commit("SET_AUTH", isLoggedIn);
  },

  async getGoogleLink() {
    let response = await api.apiCall("GET", "/auth/api/social");

    if (response.status === "success") {
      return response.data.google;
    }
  },

  async register(context, params) {
    let response = await api.apiCall("POST", "/auth/api/register", params);

    if (response.status !== "success") {
      throw response;
    }
  },

  async login({ commit, dispatch }, params) {
    try {
      let response = await api.apiCall("POST", "/auth/api/login", params);

      if (response.status === "success") {
        let data = response.data;
        let email = params.email;
        dispatch("handleLoginData", { data, email });
        return data;
      } else {
        commit("SET_AUTH", false);
        throw response;
      }
    } catch (error) {
      commit("SET_AUTH", false);
      throw error;
    }
  },

  async resendEmail(context, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/api/email-otp/resend-email",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async requestVerifyEmail(context, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/api/verify-email/save-data",
      params
    );

    if (response.status !== "success") {
      throw response;
    } else {
      return response.data;
    }
  },

  async verifyEmailOtp({ context }, params) {
    let response = await api.apiCall("POST", "/auth/api/otp/validate", params);
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async verifyEmail(context, params) {
    let response = await api.apiCall("POST", "/auth/api/verify-email", params);

    if (response.status !== "success") {
      throw response;
    }
  },

  async logout({ commit, dispatch, rootState }) {
    dispatch("preferences/setPlatform", 2, { root: true });
    localStorage.getItem("jwt_key");
    localStorage.getItem("rtoken");
    localStorage.removeItem("jwt_key");
    localStorage.removeItem("rtoken");
    localStorage.removeItem("data");
    if (rootState.user.platform == 0) {
      localStorage.removeItem("selectedPlat");
    }
    dispatch("user/initializeUser", null, { root: true });
    commit("SET_AUTH", false);
    methods.navigateToRoute("SignInView");
  },

  async validateTwoFa({ commit, dispatch, rootState }, data) {
    let request_id = localStorage.getItem("request_id");
    let params = {
      token: data.code,
    };

    let response = await api.apiCall(
      "POST",
      "/auth/jwt/totp/validate",
      params,
      data.token
    );

    if (response.status === "success") {
      localStorage.setItem("jwt_key", response.data.token);
      localStorage.setItem("rtoken", response.data.rtoken);
      localStorage.setItem(
        "data",
        methods.encryptData(
          methods.storeProfileObject(
            data.user_id,
            "",
            data.email,
            "",
            true,
            data.phone,
            data.kyc_status.toLowerCase(),
            "",
            data.business,
            data.secure_email,
            data.platform,
            data.locale
          )
        )
      );

      if (data.platform != 0 && localStorage.getItem("selectedPlat") == "") {
        dispatch("preferences/setPlatform", 2, { root: true });
      } else if (data.platform >= 3) {
        dispatch("preferences/setPlatform", 1, { root: true });
      } else {
        dispatch("preferences/setPlatform", data.platform, { root: true });
      }
      dispatch("user/initializeUser", null, { root: true });
      commit("SET_AUTH", true);

      if (
        rootState.preferences.platform == 2 &&
        data.kyc_status.toLowerCase() === "approved"
      ) {
        dispatch("referral/checkAffUser", "", { root: true });
        dispatch("referral/checkRefUser", "", { root: true });
      } else if (
        rootState.preferences.platform == 1 &&
        data.kyc_status.toLowerCase() === "approved"
      ) {
        dispatch("MY_referral/checkAffUser", "", { root: true });
        dispatch("MY_referral/checkRefUser", "", { root: true });
      }

      if (request_id) {
        methods.navigateToRoute("PaymentView", {}, { id: request_id, page: 1 });
      } else if (data.platform == "0") {
        methods.navigateToRoute("HomeView");
      } else {
        methods.navigateToRoute("GlobalView");
      }
    } else {
      throw response;
    }
  },

  // GOOGLE
  async googleRegister({ commit, dispatch }, params) {
    try {
      let response = await api.apiCall(
        "POST",
        "/auth/api/google/register",
        params
      );

      if (response.status === "success") {
        localStorage.removeItem("regRef");
        let data = response.data;
        dispatch("handleLoginData", { data });
        return data;
      } else {
        commit("SET_AUTH", false);
        throw response;
      }
    } catch (error) {
      commit("SET_AUTH", false);
      throw error;
    }
  },

  async googleLogin({ commit, dispatch }, params) {
    try {
      let response = await api.apiCall(
        "POST",
        "/auth/api/google/login",
        params
      );

      if (response.status === "success") {
        let data = response.data;
        dispatch("handleLoginData", { data });
        return data;
      } else {
        commit("SET_AUTH", false);
        throw response;
      }
    } catch (error) {
      commit("SET_AUTH", false);
      throw error;
    }
  },

  // APPLE
  async appleRegister({ commit, dispatch }, params) {
    try {
      let response = await api.apiCall(
        "POST",
        "/auth/api/apple/register",
        params
      );

      if (response.status === "success") {
        localStorage.removeItem("regRef");
        let data = response.data;
        dispatch("handleLoginData", { data });
        return data;
      } else {
        commit("SET_AUTH", false);
        throw response;
      }
    } catch (error) {
      commit("SET_AUTH", false);
      throw error;
    }
  },

  async appleLogin({ commit, dispatch }, params) {
    try {
      let response = await api.apiCall("POST", "/auth/api/apple/login", params);

      if (response.status === "success") {
        let data = response.data;
        dispatch("handleLoginData", { data });
        return data;
      } else {
        commit("SET_AUTH", false);
        throw response;
      }
    } catch (error) {
      commit("SET_AUTH", false);
      throw error;
    }
  },

  async handleLoginData({ commit, dispatch }, { data, email }) {
    commit("SET_AUTH", true);
    let locale =
      data.locale !== "" ? data.locale : localStorage.getItem("lang");
    let request_id = localStorage.getItem("request_id");
    localStorage.setItem("lang", data.locale);
    app.config.globalProperties.$i18n.locale = locale;
    if (
      localStorage.getItem("selectedPlat") &&
      localStorage.getItem("selectedPlat") == 0 &&
      data.platform != 0
    ) {
      localStorage.setItem("selectedPlat", data.platform);
    }

    dispatch("preferences/initializePreferences", null, { root: true });

    localStorage.setItem("rtoken", data.rtoken);

    if (!data.two_fa_enabled) {
      localStorage.setItem("jwt_key", data.token);
      localStorage.setItem(
        "data",
        methods.encryptData(
          methods.storeProfileObject(
            data.user_id,
            "",
            email,
            "",
            data.two_fa_enabled,
            data.phone,
            data.kyc_status.toLowerCase(),
            "",
            data.business,
            data.secure_email,
            data.platform,
            data.locale
          )
        )
      );
      dispatch("user/initializeUser", null, { root: true });

      if (data.platform != 0 && localStorage.getItem("selectedPlat") == "") {
        dispatch("preferences/setPlatform", 2, { root: true });
      } else if (data.platform >= 3) {
        dispatch("preferences/setPlatform", 1, { root: true });
      } else {
        dispatch("preferences/setPlatform", data.platform, { root: true });
      }
    }

    if (data.two_fa_enabled) {
      commit("SET_AUTH", false);
      commit("SET_USER_PROPS_DATA", {
        id: data.user_id,
        token: data.token,
        email: email,
        status: data.kyc_status.toLowerCase(),
        isBusiness: data.business,
        phone: data.phone,
        secure_email: data.secure_email,
        platform: data.platform,
        locale: data.locale,
      });

      methods.navigateToRoute("VerifyTwoFa", {}, { id: request_id });
    } else {
      if (request_id) {
        methods.navigateToRoute("PaymentView", {}, { id: request_id, page: 1 });
      } else if (data.platform == "0") {
        methods.navigateToRoute("HomeView");
      } else {
        methods.navigateToRoute("GlobalView");
      }
    }
  },

  // FORGOT PASSWORD
  async getForgotPwCode(context, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/api/users/forgot-password",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async forgotPassword(context, params) {
    let response = await api.apiCall(
      "PUT",
      "/auth/api/users/forgot-password",
      params
    );

    if (response.status !== "success") {
      throw response;
    }
  },

  async sumsubToken() {
    let response = await api.apiCall("POST", "/auth/jwt/users/applicant");

    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response);
    }
  },

  async getUserPlatform() {
    let response = await api.apiCall(
      "GET",
      "/auth/jwt/users/platform/available"
    );

    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response);
    }
  },

  // INTERNAL SEND CRYPTO
  async validateReceiver(context, params) {
    let response = await api.apiCall("GET", "/auth/jwt/users/validate", params);

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async sumsubReason() {
    let response = await api.apiCall("GET", "/auth/jwt/sumsub/label");

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async getAnnouncements(context, params) {
    let response = await api.apiCall(
      "GET",
      "/auth/api/users/announcement",
      params
    );
    if (response.status === "success") {
      return response.data;
    } else {
      console.error(response.message);
    }
  },

  //STREAM KEY
  async getStreamKey() {
    let response = await api.apiCall("POST", "/auth/jwt/user-stream-key");

    if (response.status === "success") {
      return response.data;
    } else {
      throw response.message;
    }
  },

  async extendStreamKey(context, key) {
    let response = await api.apiCall("PUT", "/auth/jwt/user-stream-key", key);

    if (response.status === "success") {
      return response.data;
    } else {
      throw response.message;
    }
  },

  async requestValidation({ context }, params) {
    let response = await api.apiCall("GET", "/auth/jwt/request-auth", params);
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },
  async validateOtp({ context }, params) {
    let response = await api.apiCall("POST", "/auth/jwt/otp/validate", params);
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async validateTotp({ context }, params) {
    let response = await api.apiCall(
      "POST",
      "/auth/jwt/totp/validate-action",
      params
    );
    if (response.status == "success") {
      return response.data;
    } else {
      throw response;
    }
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
};
