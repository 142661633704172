<template>
    <div class="txn-history-date-range">
        <DatePicker 
            :value="modelValue"
            type="date"
            range
            :format="format"
            :editable="isEditable"
            :confirm="showConfirm"
            :confirm-text="confirmText || defaultConfirmTitle"
            :placeholder="placeholder"
            range-separator=" - "
            @confirm="handleConfirm"
            @clear="handleInput"
        >
            <template #icon-calendar>
                <img src="@/assets/images/kyc/calendar.svg" />
            </template>
        </DatePicker>

        <div class="error-message">{{ errorMessage }}</div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: { type: Array },
        format: { type: String, default: "D MMM YYYY" },
        showConfirm: { type: Boolean, default: false },
        confirmText: { type: String },
        isEditable: { type: Boolean },
        placeholder: { type: String },
        clearErrorMessage: { type: Boolean, default: false }
    },

    watch: {
        clearErrorMessage: {
            handler() {
                this.errorMessage = "";
            }
        }
    },

    data() {
        return {
            errorMessage: ""
        }
    },

    methods: {
        handleConfirm(event) {
            this.errorMessage = "";
            if(event[0] == null) {
                this.errorMessage = this.$t("wallet.plsSelectTwoDate");
            } else if(event[1] == null) {
                this.$emit("update:modelValue", [event[0], event[0]]);
            } else {
                this.$emit("update:modelValue", event);
            }
        },

        handleInput(event) {
            this.$emit("update:modelValue", event);
        }
    },

    computed: {
        defaultConfirmTitle(){
            return this.$t("Trading.filter");
        }
    }
}
</script>

<style>

</style>