<template>
    <v-dialog
        v-model="$store.state.preferences.showCookieModal"
        class="dialog-modal"
        max-width="500px"
        persistent
    >
        <div class="modal-body-custom">
            <img
                src="@/assets/images/modal/cookie_policy.png"
                width="150px"
            />
            <div class="modal-title">{{ $t("Modal.CookieNotice") }}</div>
            <div class="modal-description">
                {{ $t("Modal.cookieNoticeDesc") }}
                <button
                    @click="navigateToRoute('CookiePolicy')"
                    class="secondary-color-text"
                >
                    {{ $t("Footer.CookiePolicy") }}
                </button>.
            </div>

            <button class="main-btn w-50" @click="$emit('enable')">
                {{ $t("Button.Accept") }}
            </button>
        </div>
    </v-dialog>
</template>

<script>
export default {
   
}
</script>

<style>

</style>