// New file: tokenRefreshService.js
export default {
  isRefreshing: false,
  failedQueue: [],

  processQueue(error, token = null) {
    this.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedQueue = [];
  },
};
