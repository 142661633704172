import api from "@/assets/api/api_my.js";

const state = {
    symbol: "MYR",
    new_bank: {
        isDuitNow: false
    }
}

const mutations = {
    SET_SYMBOL(state, symbol) {
        state.symbol = symbol;
    },

    SET_ADD_NEW_BANK(state, new_bank) {
        state.new_bank.isDuitNow = new_bank.isDuitNow;
    }
}

const actions = {
    setSymbol({ commit }, symbol) {
        commit("SET_SYMBOL", symbol);
    },

    async getFiatHistory(context, params) {
        let response = await api.apiCall("GET", "/fiat/jwt/get-transaction", params);

        if(response.status === "success") {
            let data = response.data.transactionsFiatRes != null ? response.data.transactionsFiatRes : [];
            return {
                history: data,
                pages: response.data.pages
            };
        } else {
            console.error(response.message);
        }
    },

    // BANK
    async getBank() {
        let response = await api.apiCall("GET", "/fiat/jwt/users/banks");

        if(response.status === "success") {
            return {
                bank: response.data != null ? response.data : []
            }
        } else {
            console.error(response.message);
        }
    },

    async deleteUserBank(context, params) {
        let response = await api.apiCall("DELETE", "/fiat/jwt/users/bank", params);

        if(response.status !== "success") {
            throw response;
        }

    },

    async addNewBank(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/users/my_bank", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    // WITHDRAWAL
    setAddNewBank({ commit }, new_bank) {
        commit("SET_ADD_NEW_BANK", new_bank);
    },

    async getDuitNow() {
        let response = await api.apiCall("GET", "/identity/jwt/users/duitnow");

        if(response.status === "success") {
            return response.data;
        } else {
            throw response;
        }
    },

    async getBankList() {
        let response = await api.apiCall("GET", "/fiat/jwt/banklist");

        if(response.status === "success") {
            return response.data
        } else {
            throw response;
        }
    },

    async requestWithdraw(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/request-withdraw/save-data", params);

        if(response.status === "success") {
            return response.data
        } else {
            throw response;
        }    
    },

    async withdraw(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/request-withdraw", params);

        if(response.status !== "success") {
            throw response;
        }
    },

    // DEPOSIT (MYR)
    async getDepBankInfo(context, params) {
        let response = await api.apiCall("GET", "/fiat/jwt/internal-fiat", params);

        if(response.status === "success") {
            let data = response.data != null ? response.data : [];

            return {
                bank: data[0]
            }
        } else {
            console.error(response.message);
        }
    },
    
    async createDeposit(context, params) {
        let response = await api.apiCall("POST", "/fiat/jwt/curlec/order-create", params);

        if(response.status === "success") {
            return response.data;
        } else {
            throw response;
        }
    },

    async getTransactionDetails(context, params) {
        let response = await api.apiCall("GET", "/fiat/jwt/get-transaction-details", params);

        if(response.status === "success"){
            return response.data
        } else{
            throw response
        }
    },

    async getRemainingLimit(context) {
        let response = await api.apiCall(
          "GET",
          "/fiat/jwt/withdrawal/remaining-limit"
        );
    
        if (response.status === "success") {
          return response.data;
        } else {
          console.error(response.message);
        }
      },
}

export const MY_fiat = {
    namespaced: true,
    state,
    mutations, 
    actions
}