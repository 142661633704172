<template>
    <v-dialog
        v-model="isShow"
        :class="`dialog-modal`"
        max-width="500px"
        :persistent="persistent"
        @click:outside="$emit('close')"
    >
        <div class="modal-body-custom" :id="modalId">
            <button
                @click="$emit('close')"
                class="modal-close-btn"
                v-if="isCloseBtn"
            >
                <img src="@/assets/images/modal/close-circle.png" width="22px"/>
            </button>

            <img 
                :src="require(`@/assets/images/modal/share-icon.png`)"
                class="modal-image"
                width="50px"
            />

            <div class="modal-title" :class="titleClass" v-if="title">
                {{ title }}
            </div>
            <div class="modal-description" v-if="description">
                <div>
                    {{ description }}
                </div>
                <div class="modal-description" v-if="description2">
                    <div>{{ description2 }}</div>
                </div>
                
                <slot name="description" v-if="!description"></slot>
                
                <RemarkContainer 
                :icon="remark.icon"
                :isWarning="remark.isWarning"
                :text="remark.text"
                v-if="remark"
                />
            </div>
            <div class="social-media-container">
                <ShareNetwork
                    :network="social.name"
                    :title="social.name === 'Email' ? shareTitle[1] : shareTitle[0]"
                    :description="social.name === 'Email' ? shareDesc : ''"
                    :url="''"
                    v-for="(social, index) in socialMedia" :key="index"
                    ref="shareNetworkRef"
                    @open="runWorkaround(index)"
                >
                    <InlineSvg :src="require(`@/assets/images/modal/${social.icon}`)"/>
                    <div>{{ social.name }}</div>
                </ShareNetwork>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        modalId: { type: String },
        dialog: { type: Boolean },
        title: { type: String },
        titleClass: { type: String, default: "" },
        description: { type: String },
        description2: {type: String},
        remark: { type: Object, default: () => {} },
        isCloseBtn: { type: Boolean, default: true },
        persistent: { type: Boolean, default: false },
        details: { type: Object },
        link: {type: String},
        shareTitle: {type: Array},
        shareDesc: {type: String, default: ""},
    },

    data(){
        return{
            socialMedia: [
                {
                    icon: "Whatsapp.svg",
                    name: "WhatsApp"
                },
                {
                    icon: "Telegram.svg",
                    name: "Telegram"
                },
                // {
                //     icon: "Messenger.svg",
                //     name: "Messenger"
                // },
                // {
                //     icon: "Wechat.svg",
                //     name: "Wechat"
                // },
                {
                    icon: "Line.svg",
                    name: "Line"
                },
                {
                    icon: "Email.svg",
                    name: "Email"
                },
            ],
        }
    },

    computed: {
        isShow() {
            return this.dialog;
        }
    },

    methods: {
        runWorkaround(index) {
            let refIndex = this.$refs.shareNetworkRef[index];
            if (refIndex === null) return;
            
            clearInterval(refIndex.popupInterval);
            refIndex.popupWindow = undefined;
            this.$emit("close");
        }
    }
}
</script>

<style>
.social-media-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 100%;
    font-size: 13px;
    padding: 20px 0px;
}

.social-media-container > a {
    width: 100px;
}

.social-media-container > a > div {
    margin-top: 10px;
    color: var(--header-text);
}

.social-media-container svg {
    width: 40px;
    height: 40px;
}
</style>