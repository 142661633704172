<template>
    <div class="input-outer-container">
        <div class="input-label" v-if="label != ''">
            {{ label }}
            <span class="secondary-color-text" v-if="email">{{ email }}</span>
            <span class="optional-label" v-if="optional">*{{ $t("BusinessKyc.optional") }}</span>
            <button class="info-circle" @click="infoIconClick" v-if="infoIcon">
                <InlineSvg :src="require('@/assets/images/modal/info-circle.svg')" width="20px" />
            </button>
        </div>

        <div class="input-container" :style="{ 'background-color': backgroundColor }"
            :class="errorMessage ? 'red-bordered' : ''">
            <input :id="id" class="input-text" :class="isAmt && 'w-85'" :name="name" :value="modelValue"
                :placeholder="placeholder" :autocomplete="autocomplete" type="text" @focus="showCross = true"
                @blur="hideCross()" @keyup.enter="$emit('keyUpAction')" @input="handleNumInput"
                @keydown="insertNumber($event, decimalPlaces)" :disabled="disabled" v-if="decimalPlaces >= 0" />

            <input :id="id" class="input-text" :autocomplete="autocomplete" :class="isAmt && 'w-85'" :name="name"
                :value="modelValue" :placeholder="placeholder" :type="inputType" @focus="showCross = true"
                @blur="hideCross()" @keyup.enter="$emit('keyUpAction')" @input="handleInput"
                @keydown="$emit('keyDownAction', $event)" :disabled="disabled" v-else />

            <button @click="$emit('reset')" class="input-close" v-show="showCross">
                <v-icon>mdi-close</v-icon>
            </button>
            <div class="input-symbol" :class="!hasBtn && 'noBtn'" v-if="symbol">{{ symbol }}</div>

            <button class="showPW" @click="isShowPassword" v-if="hidePassword">
                <InlineSvg :src="require(`@/assets/images/auth/${isShow ? 'eye.svg' : 'eye-slash.svg'}`)"
                    width="18px" />
            </button>

            <button class="send-code" @click="$emit('send')" v-if="sendCode" :disabled="sendCodeDisabled">
                <span class="spinner-border-sm spinner-border" v-if="isLoading"></span>
                <span :class="sendCodeDisabled ? 'gray90-text' : ''" v-else>{{ rightButtonText }}</span>
            </button>

            <slot name="coin"></slot>
        </div>

        <div class="remark-cointainer">
            <div class="remark-text" :class="remarkText2 ? 'remark-text-40' : ''" v-if="remarkText">
                {{ remarkText }}
            </div>
            <div class="remark-text remark-2" :class="remarkText2 ? 'remark-text-60' : ''" v-if="remarkText2">
                {{ remarkText2 }}
            </div>
        </div>
        <slot name="remark"></slot>
        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "" },
        infoIcon: { type: Boolean, default: false },
        email: { type: String },
        id: { type: String },
        name: { type: String },
        placeholder: { type: String },
        type: { type: String, default: "text" },
        modelValue: { type: String },
        hidePassword: { type: Boolean, default: false },
        errorMessage: { type: String, default: "" },
        remarkText: { type: String, default: "" },
        remarkText2: { type: String, default: "" },
        rightButtonText: { type: String },
        sendCode: { type: Boolean, default: false },
        sendCodeDisabled: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        optional: { type: Boolean, default: false },
        maxLength: { type: Number, default: Infinity },
        decimalPlaces: { type: Number, default: -1 },
        isLoading: { type: Boolean, default: false },
        symbol: { type: String, default: "" },
        isAmt: { type: Boolean, default: false },
        hasBtn: { type: Boolean, default: false },
        backgroundColor: { type: String, default: "var(--white)" },
        autocomplete: { type: String, default: "off" }

    },

    data() {
        return {
            isShow: false,
            inputType: this.type,
            showCross: false,
            hideCrossTimeout: ""
        }
    },

    methods: {
        isShowPassword() {
            this.isShow = !this.isShow;

            if (this.isShow) {
                this.inputType = "text";
            } else {
                this.inputType = "password";
            }
        },

        infoIconClick() {

            this.$emit('infoIconClicked');

        },

        hideCross() {
            clearTimeout(this.hideCrossTimeout);
            this.hideCrossTimeout = setTimeout(() => {
                this.showCross = false;
            }, 250);
        },

        handleInput(event) {
            this.$emit("clearError");
            if ((event.target.value).length >= this.maxLength) {
                event.target.value = event.target.value.slice(0, this.maxLength);
            }

            this.$emit("update:modelValue", event.target.value);
        },

        handleNumInput(event) {
            this.$emit("clearError");
            if ((event.target.value).length >= this.maxLength) {
                event.target.value = event.target.value.slice(0, this.maxLength);
            }

            let value = this.handleNumberInput(event, this.decimalPlaces);

            if (value) {
                this.$emit("update:modelValue", event.target.value);
            }
        }
    }
}
</script>

<style>
.input-outer-container .info-circle {
    vertical-align: middle;
}

.noBtn {
    margin: 0px 0px 0px auto !important;
    padding: 0px 10px !important;
}

.remark-cointainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.remark-2 {
    text-align: right !important;
}

@media only screen and (max-width: 768px) {
    .remark-text-40 {
        width: 40%;
    }

    .remark-text-60 {
        width: 60%;
    }
}
</style>