<template>
    <div>
      <v-dialog v-model="isShow" class="select-coin-modal" @click:outside="hideModal" max-width="500px" :id="id">
        <div class="modal-body-custom">
          <button @click="hideModal" class="modal-close-btn">
            <img src="@/assets/images/modal/close-circle.png" width="22px"/>
          </button>
  
          <div class="modal-title">
            {{ label }}
          </div>
  
          <div
            class="modal-search-bar"
          >
            <InlineSvg
              :src="require('@/assets/images/wallet/search-icon.svg')"
              width="25px"
              @click="focusInput"
              v-if="!isSearchExpanded"
            />
  
            <input
              name="search"
              ref="searchInput"
              v-model="searchQuery"
              :placeholder="$t('Button.Search')"
              class="modal-search-input"
              :class="isSearchExpanded ? 'searchShow bg-gray20' : ''"
              @blur="!searchQuery ? isSearchExpanded = modelValue ? true : false : ''"
            />
  
            <button
              @click="clearText"
              class="input-close bg-gray20"
              v-if="searchQuery && isSearchExpanded"
            >
              <v-icon>mdi-close</v-icon>
            </button>
          </div>
        </div>
  
        <div>
          <div class="auto-invest-modal-crypto-header">
            <div class="auto-invest-modal-header-name">{{ $t('wallet.cryptocurrency') }}</div>
            <div class="auto-invest-modal-header-percent">{{ $t('Market.change24') }}</div>
            <div class="auto-invest-modal-price">
              <div class="auto-invest-modal-header-price">
                {{ $t("Trading.price") }} ({{
                  quote.asset
                }})
              </div>
            </div>
          </div>
  
          <div class="modal-crypto-content" id="modal-select-coin">
            <div
              class="auto-invest-modal-detail-row"
              v-for="(item, index) in filteredData"
              :key="item.id"
              @click="selectData(item, index)"
            >
              <div class="auto-invest-modal-detail-inner-row">
                <v-checkbox
                    v-model="item.is_tick"
                    class="coin-checkbox"
                    :class="item.is_tick ? 'isTick' : ''"
                    hide-details
                    color="var(--secondary-color)"
                    :disabled="disabledTick ? true : false"
                />
                <img :src="item.image" :alt="item.symbol" width="40px" />
                <div class="auto-invest-modal-detail-name">
                  {{ item.symbol }}
                </div>
              </div>

              <div class="auto-invest-modal-detail-percent">
                <span :class="!isPositive(item.change) ? 'fail-text' : 'success-text'">
                  {{ !isPositive(item.change) ? "-" : "+" }}
                  {{ numberSeparator(item.change, 2).replaceAll("-", "") }}%
                </span>
              </div>
  
              <div
                class="auto-invest-modal-detail-price text-uppercase"
              >
                  <div>
                    {{ numberSeparator(item.price, item.disp_price_scale) }}
                  </div>
              </div>
            </div>
          </div>
        </div>
        <span class="remark-text">{{ $t("AutoInvestPlan.cryptoSelected", {total: selectedLength}) }}</span>
        <button class="main-btn modal-btn" @click="setCoinList">{{  $t('Button.confirm') }}</button>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      id: { type: String },
      selectedCoin: { type: Object },
      dialog: { type: Boolean },
      quote: { type: Array },
      data: { type: Array },
      priceFormat: { type: Function },
      isFunds: { type: Boolean, default: false },
      label: { type: String, default: ""},
      disabled: { type: Boolean, default: false },
    },
  
    data() {
      return {
        filteredData: [],
        searchQuery: "",
        value: "",
        selected: "",
        status: "",
        isSearchExpanded: false,
        tokenData: "",
        disabledTick: true,
        tickCoin: [],
      };
    },
  
    computed: {
      deviceWidth() {
        return this.$store.state.preferences.deviceWidth;
      },

      isShow() {
          return this.dialog;
      },

      selectedLength() {
        return this.tickCoin.length;
      }
    },
  
    created() {
      this.filteredData = this.data;
      this.coinSort();
    },
  
    beforeMount() {
      this.selected = this.selectedCoin;
    },
  
    watch: {
      isShow: {
        handler() {
          if(!this.isFunds) {
            this.checkIsTick(true);
          }
        }
      },
      
      data: {
        handler() {
          this.filteredData = this.data;
          this.coinSort();
        },
      },
  
      selectedCoin: {
        handler() {
          this.selected = this.selectedCoin;
        },
      },
  
      searchQuery: {
        handler() {
          this.filter(this.searchQuery);
        },
      },
    },
  
    methods: {
      hideModal() {
        this.clearText();
        this.$emit('close');
      },

      filter(text) {
        let filtered = this.data;
        this.filteredData = filtered.filter(
        (el) =>
          el.symbol.toLowerCase().includes(text.toLowerCase()) || el.name.toLowerCase().includes(text.toLowerCase())
        );
        this.coinSort();
      },
  
      clearText() {
        this.searchQuery = "";
        this.coinSort();
        this.focusInput();
      },
  
      selectData(token, index) {
        if(this.isFunds) {
          this.tickCoin = token;
          this.setCoinList();
        } else {
          if(this.filteredData[index].is_tick) {
            if(this.selectedLength > 1) {
              this.disabledTick = false;
              this.filteredData[index].is_tick = false;
              this.tickCoin = this.tickCoin.filter((coin) => coin !== token.symbol);
              if(this.selectedLength == 1) {
                this.disabledTick = true;
              }
            }
          } else {
            if(this.selectedLength <= 4) {
              if(this.selectedLength == 4) {
                this.disabledTick = true;
              } else {
                this.disabledTick = false;
              }
              this.filteredData[index].is_tick = true;
              this.tickCoin.push(token.symbol);
            } else {
              this.disabledTick = true;
            }
          }
        }
      },

      setCoinList() {
        this.$emit("action", this.tickCoin);
        this.hideModal();
      },
  
      coinSort() {
          // let priceSorted= this.filteredData.slice().sort(function (a, b) {
          //   return a.price - b.price;
          // });
          
          // this.filteredData = priceSorted.reverse();

          this.filteredData.map((data)=> {
            data.is_tick = false;
          });

          if(!this.isFunds) {
            this.checkIsTick(true);
          }
      },

      checkIsTick(action) {
        if(action) {
          this.tickCoin = [];
          for(let i = 0; i < this.filteredData.length; i++) {
            for(let j = 0; j < this.selected.length; j++) {
              if(this.filteredData[i].symbol === this.selected[j].asset) {
                this.filteredData[i].is_tick = true;
                this.tickCoin.push(this.filteredData[i].symbol);
                break;
              } else {
                this.filteredData[i].is_tick = false;
              }
            }
          }
        } else {
          for(let i = 0; i < this.filteredData.length; i++) {
            for(let j = 0; j < this.tickCoin.length; j++) {
              if(this.filteredData[i].symbol === this.tickCoin[j]) {
                this.filteredData[i].is_tick = true;
                break;
              } else {
                this.filteredData[i].is_tick = false;
              }
            }
          }
        }
      },

      isPositive(value) {
          let percent = value ? value : 0.00;
          if((percent.toString()).includes("-")) {
              return false;
          } else {
              return true;
          }
      },
  
      focusInput() {
        this.isSearchExpanded = true;
        this.$nextTick(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
          }
        });
      },
    },
  };
  </script>
  
  <style>
  .select-coin-modal .v-overlay__content {
    border-radius: 0.5rem !important;
    background-color: var(--gray10);
    padding: 20px 20px 60px 20px;
    max-height: calc(100% - 30px);
  }
  
  .select-coin-modal .modal-desc {
    color: var(--gray);
  }

  .coin-checkbox .v-input__control .v-selection-control {
    min-height: unset;
  }

  .coin-checkbox .v-selection-control__input {
    align-self: center;
    align-items: center !important;
    margin-bottom: 2px;
  }

  .auto-invest-modal-header-name,
  .auto-invest-modal-header-percent,
  .auto-invest-modal-header-price {
    font-size: 14px;
    color: var(--gray90);
  }

  .auto-invest-modal-header {
    margin-bottom: 0;
  }

  .auto-invest-modal-crypto-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
  }

  .auto-invest-modal-detail-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 10px;
    width: 100%;
    cursor: pointer;
    min-height: 68px;
    font-weight: 500;
    border-radius: 16px;
    background-color: var(--white);
    margin: 5px 0px;
  }
  
  .auto-invest-modal-detail-inner-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .auto-invest-modal-detail-name {
    margin-right: 10px;
    width: 35px
  }

  .auto-invest-modal-header-name,
  .auto-invest-modal-detail-inner-row {
    width: 35%;
    text-align: left;
  }

  .auto-invest-modal-header-percent,
  .auto-invest-modal-detail-percent {
    width: 20%;
    text-align: center;
  }

  .auto-invest-modal-price,
  .auto-invest-modal-detail-price {
    width: 40%;
    text-align: right;
  }

  @media screen and (max-width: 768px) {
    .auto-invest-modal-crypto-header {
      padding: 0px 10px;
    }

    .coin-checkbox {
      font-size: 12px;
      width: 35px;
    }

    .auto-invest-modal-header-name,
    .auto-invest-modal-detail-inner-row {
      width: 40%;
    }

    .auto-invest-modal-detail-inner-row > img {
      width: 25px;
    }

    .auto-invest-modal-detail-name {
      width: 20px;
    }

    .auto-invest-modal-detail-name,
    .auto-invest-modal-detail-percent,
    .auto-invest-modal-detail-price {
      font-size: 12px;
    }
  }
  
  .coin-checkbox.isTick .v-selection-control--disabled {
    color: var(--secondary-color);
    opacity: 1;
  }
  </style>
  
  <style lang="css" scoped>
    .modal-btn {
      margin: 30px auto 0px auto;
    }
  </style>