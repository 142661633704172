<template>
    <div class="icon-text-outer">
        <div :class="defaultColor ? 'icon-text-wrapper' : 'icon-text-wrapper-default'">
            <div class="icon-div">
                <InlineSvg 
                    :src="require(`@/assets/images/${icon}`)"
                    width="24px"
                />
            </div>
            

            <div class="icon-text">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: { type: String },
        text: { type: String },
        defaultColor: {type: Boolean, default: true},
    }
}
</script>

<style>
.icon-text-outer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.icon-text-wrapper,
.icon-text-wrapper-default {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.icon-text-wrapper .icon-div,
.icon-text-wrapper-default .icon-div {
    max-width: 25px;
}

.icon-text-wrapper svg path {
    fill: var(--secondary-color);
}

.icon-text-wrapper #icon-stroke {
    stroke: var(--secondary-color);
}

.icon-text {
    margin-left: 8px;
    color: var(--header-text);
    font-size: 15px;
    margin-top: 2px;
}
</style>