<template>
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12V18C20 20.21 18.21 22 16 22H8C5.79 22 4 20.21 4 18V12C4 11.45 4.45 11 5 11H6.97C7.52 11 7.97 11.45 7.97 12V15.14C7.97 15.88 8.38 16.56 9.03 16.91C9.32 17.07 9.64 17.15 9.97 17.15C10.35 17.15 10.73 17.04 11.06 16.82L12.01 16.2L12.89 16.79C13.5 17.2 14.28 17.25 14.93 16.9C15.59 16.55 16 15.88 16 15.13V12C16 11.45 16.45 11 17 11H19C19.55 11 20 11.45 20 12Z" fill="white"/>
        <path d="M21.5 7V8C21.5 9.1 20.97 10 19.5 10H4.5C2.97 10 2.5 9.1 2.5 8V7C2.5 5.9 2.97 5 4.5 5H19.5C20.97 5 21.5 5.9 21.5 7Z" fill="white"/>
        <path d="M11.6398 4.9975H6.11978C5.77978 4.6275 5.78978 4.0575 6.14978 3.6975L7.56978 2.2775C7.93978 1.9075 8.54978 1.9075 8.91978 2.2775L11.6398 4.9975Z" fill="white"/>
        <path d="M17.8701 4.9975H12.3501L15.0701 2.2775C15.4401 1.9075 16.0501 1.9075 16.4201 2.2775L17.8401 3.6975C18.2001 4.0575 18.2101 4.6275 17.8701 4.9975Z" fill="white"/>
        <path d="M13.9699 11C14.5199 11 14.9699 11.45 14.9699 12V15.13C14.9699 15.93 14.0799 16.41 13.4199 15.96L12.5199 15.36C12.1899 15.14 11.7599 15.14 11.4199 15.36L10.4799 15.98C9.81994 16.42 8.93994 15.94 8.93994 15.15V12C8.93994 11.45 9.38994 11 9.93994 11H13.9699Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "RewardIcon"
}
</script>

<style>

</style>